<template>
  <div class="profile--container">
    <form
      class="columns profile--settings custom-scroll"
      @submit.prevent="updateUser"
    >
      <woot-base-button
        class="settings-button"
        type="submit"
        :disabled="$v.$invalid"
        :loading="isUpdating"
      >
        {{ $t('PROFILE_SETTINGS.BTN_TEXT') }}
      </woot-base-button>
      <div class="profile--settings--row">
        <woot-modal-header
          :header-title="$t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.TITLE')"
          :header-content="$t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.NOTE')"
        />
        <div class="row form-box">
          <div class="columns small-12">
            <woot-avatar-uploader
              :src="avatarUrl"
              :username="name"
              @change="handleImageUpload"
            />
          </div>
          <woot-input
            v-model.trim="name"
            class="small-12 medium-6 columns content-margin"
            :class="{ error: $v.name.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.NAME.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER')"
            @input="$v.name.$touch"
          />
          <woot-input
            v-model.trim="displayName"
            class="small-12 medium-6 columns content-margin"
            :class="{ error: $v.displayName.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER')"
            @input="$v.displayName.$touch"
          />
          <woot-input
            v-model.trim="email"
            class="small-12 medium-6 columns content-margin"
            :class="{ error: $v.email.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.EMAIL.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER')"
            :has-error="$v.email.$error"
            :error="$t('PROFILE_SETTINGS.FORM.EMAIL.ERROR')"
            @input="$v.email.$touch"
          />
          <woot-input
            v-model.trim="phone"
            class="small-12 medium-6 columns content-margin"
            :class="{ error: $v.phone.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.PHONE.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.PHONE.PLACEHOLDER')"
            :has-error="$v.phone.$error"
            :error="$t('PROFILE_SETTINGS.FORM.PHONE.ERROR')"
            @input="$v.phone.$touch"
          />
          <woot-input
            v-model.trim="webRTCId"
            class="small-12 medium-6 columns content-margin"
            :label="$t('PROFILE_SETTINGS.FORM.WEB_RTC_ID.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.WEB_RTC_ID.PLACEHOLDER')"
          />
          <div class="columns small-12 medium-6">
            <label class="body-b2 text-dark formlabel">
              {{ $t('PROFILE_SETTINGS.FORM.TIMEZONE.LABEL') }}
            </label>
            <multiselect
              v-model="timezone"
              :options="timeZones"
              deselect-label=""
              select-label=""
              selected-label=""
              track-by="value"
              label="label"
              :close-on-select="true"
              :placeholder="$t('PROFILE_SETTINGS.FORM.TIMEZONE.PLACEHOLDER')"
              :allow-empty="false"
            />
          </div>
          <div class="columns small-12 medium-6">
            <woot-input
              v-model.trim="address"
              rows="6"
              type="text"
              :label="$t('PROFILE_SETTINGS.FORM.ADDRESS.LABEL')"
              :placeholder="$t('PROFILE_SETTINGS.FORM.ADDRESS.PLACEHOLDER')"
              @input="$v.address.$touch"
            />
          </div>
        </div>
      </div>

      <div class="profile--settings--row">
        <woot-modal-header
          :header-title="$t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.TITLE')"
          :header-content="$t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.NOTE')"
        />

        <woot-primary-button
          :name="$t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.CHANGE_PASSWORD')"
          type="button"
          @click="onRequestPasswordChange"
        />
      </div>

      <notification-settings />

      <div class="profile--settings--row">
        <woot-modal-header
          :header-title="$t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.TITLE')"
          :header-content="$t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE')"
        />
        <div class="columns medium-6">
          <woot-code :script="currentUser.access_token" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { isValidPhoneNumber } from 'libphonenumber-js';
import { required, minLength, email } from 'vuelidate/lib/validators';

import { timeZoneOptions } from 'dashboard/routes/dashboard/settings/inbox/helpers/businessHour';
import NotificationSettings from './NotificationSettings';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

const DEFAULT_TIMEZONE = {
  label: 'New Delhi',
  key: 'Asia/Kolkata',
};

export default {
  components: {
    NotificationSettings,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      avatarFile: '',
      avatarUrl: '',
      name: '',
      phone: '',
      address: '',
      displayName: '',
      email: '',
      webRTCId: '',
      isUpdating: false,
      timezone: DEFAULT_TIMEZONE,
    };
  },
  validations: {
    name: {
      required,
    },
    displayName: {},
    address: {},
    email: {
      required,
      email,
    },
    phone: {
      isPhoneNumber(value) {
        if (!value) return true;
        return isValidPhoneNumber('+' + value);
      },
      minLength(value) {
        if (!value) return true;
        return value.length >= 10;
      },
      maxLength(value) {
        if (!value) return true;
        return value.length <= 14;
      },
    },
    password: {
      minLength: minLength(6),
    },
    currentPassword: {
      minLength: minLength(6),
    },
    passwordConfirmation: {
      minLength: minLength(6),
      isEqPassword(value) {
        if (value !== this.password) {
          return false;
        }
        return true;
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
    }),
    timeZones() {
      return [...timeZoneOptions()];
    },
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
  },
  methods: {
    initializeUser() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.avatarUrl = this.currentUser.avatar_url;
      this.displayName = this.currentUser.display_name;
      this.phone = this.currentUser.phone_number;
      this.address = this.currentUser.address;
      this.webRTCId = this.currentUser.web_rtc_id;
      this.timezone =
        this.timeZones.find(item => this.currentUser.timezone === item.value) ||
        DEFAULT_TIMEZONE;
    },
    updateUser() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.isUpdating = true;
      const hasEmailChanged = this.currentUser.email !== this.email;

      this.$store
        .dispatch('updateProfile', {
          name: this.name,
          email: this.email,
          address: this.address,
          phone_number: this.phone,
          avatar: this.avatarFile,
          timezone: this.timezone.value,
          display_name: this.displayName,
          web_rtc_id: this.webRTCId,
        })
        .then(() => {
          this.isUpdating = false;
          this.mix_panel_clicked_update_profile_settings();

          if (hasEmailChanged) {
            this.$store.dispatch('logout');
            this.showAlert(
              this.$t('PROFILE_SETTINGS.AFTER_EMAIL_CHANGED'),
              'info'
            );
          } else {
            this.showAlert(
              this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'),
              'success'
            );
          }
        })
        .catch(error => {
          if (error.error) {
            this.showAlert(error.error, 'error');
          } else {
            this.showAlert(
              this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR'),
              'error'
            );
          }
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async onRequestPasswordChange() {
      try {
        await this.$store.dispatch('requestPasswordChange', {
          email: this.email,
        });
        this.showAlert(
          this.$t(
            'PROFILE_SETTINGS.FORM.PASSWORD_SECTION.CHANGE_PASSWORD_SUCCESS'
          ),
          'info'
        );
      } catch {
        this.showAlert(
          this.$t(
            'PROFILE_SETTINGS.FORM.PASSWORD_SECTION.CHANGE_PASSWORD_ERROR'
          ),
          'error'
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile {
  &--container {
    position: relative;
    height: 100%;
  }
}

.profile--settings {
  @include elevation-1dp;

  background: $color-white;
  height: calc(100% - 10rem);
  margin: $space-largest $space-normal $space-normal;
  border-radius: $border-radius;
  padding: $space-slab;
  padding-right: $zero;
  overflow: auto;

  .settings-button {
    top: $space-normal;
  }
}

.profile--settings--row {
  padding: $space-normal;
  padding-right: $zero;

  .form-box {
    padding: $space-medium $zero;
    margin-left: $space-medium;
    border-bottom: 3px solid rgba($secondary-blue, 0.3);

    .small-12,
    .medium-6 {
      padding-right: $space-larger;
    }
  }

  .page-top-bar {
    padding: 0;
    // padding-bottom: $space-normal;
    min-width: 100%;
  }
}
.form-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.content-margin {
  margin-bottom: 2.4rem;
}
</style>
