<template>
  <div class="column custom-scroll overflow-overlay">
    <woot-base-button
      v-if="!isNewPage"
      layer-fill="white"
      class="settings-button"
      :loading="isUpdating"
      @click="updateAgents"
    >
      Update
    </woot-base-button>
    <page-header
      v-if="isNewPage && inbox && inbox.name"
      :header-title="inbox.name"
    />
    <div class="settings-sub-menu-content--larger">
      <div>
        <span
          class="title-h5 text-dark mg-right--slab"
          v-text="
            isAFacebookInbox
              ? $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS') + ' For'
              : $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')
          "
        />
        <span
          v-if="!isAFacebookInbox"
          class="body-b3 text-light"
          v-text="
            '(' + $t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT') + ')'
          "
        />
        <div v-if="isAFacebookInbox" class="flex-row gap--micro mg-top--small">
          <woot-base-button
            :variant="showCommentCollaborators ? 'primary' : 'secondary'"
            size="small"
            @click="showCommentCollaborators = true"
          >
            Comments
          </woot-base-button>
          <woot-base-button
            :variant="showCommentCollaborators ? 'secondary' : 'primary'"
            size="small"
            @click="showCommentCollaborators = false"
          >
            Direct Messages
          </woot-base-button>
        </div>
      </div>
      <div
        class="flex-row flex-justify--between flex-align gap--slab mg-top--normal search-container"
      >
        <search-box
          placeholder="Search for Agent name/role"
          custom-class="search-box"
          :value="search"
          @setSearch="setSearch"
        />
        <woot-base-button
          style="height: 4.4rem"
          variant="secondary"
          @click="openAddPopup"
        >
          Add agent
        </woot-base-button>
      </div>
      <div class="mg-top full-width w-100">
        <reference-box
          v-if="showChannelAgentListBanner"
          class="reference-box"
          icon-name="info"
          icon-size="medium"
          view-box="0 0 24 24"
          icon-color="warning"
          :content="channelAgentListBannerContent"
        />
      </div>
      <div class="pd-top--two bg-green mg-bottom--slab">
        <data-table
          :columns="tableColumns"
          :table-data="addedCollaborators"
          :loading="uiFlags.isFetching || isFetchingMembers"
          :enable-animations="false"
          title="Collaborators Added"
        >
          <template v-slot:actions="{ row: data }">
            <woot-base-button
              variant="secondary-danger"
              size="small"
              class="custom-button"
              @click="onRemoveMember(data)"
            >
              Remove
            </woot-base-button>
          </template>
          <template v-slot:zero-state>
            <span class="title-h5 text-light" v-text="'None'" />
          </template>
        </data-table>
      </div>
      <div class="pd-top bg-white mg-bottom--larger">
        <data-table
          :columns="tableColumns"
          :table-data="notAddedCollaborators"
          :loading="uiFlags.isFetching || isFetchingMembers"
          :enable-animations="false"
          title="Available Collaborators"
        >
          <template v-slot:actions="{ row: data }">
            <woot-base-button
              variant="secondary"
              size="small"
              class="custom-button"
              @click="onAddCollaborator(data)"
            >
              Add to inbox
            </woot-base-button>
          </template>
          <template v-slot:zero-state>
            <span class="title-h5 text-light" v-text="'None'" />
          </template>
        </data-table>
      </div>
    </div>
    <div
      v-if="isNewPage"
      class="medium-12 columns settings-sub-menu-bottom-nav"
    >
      <woot-base-button
        variant="secondary"
        @click.prevent="() => $router.go(-1)"
      >
        Back
      </woot-base-button>
      <woot-base-button
        :disabled="isAKnowlarityInbox && !addedCollaborators.length"
        @click="updateAgents"
      >
        Finish
      </woot-base-button>
    </div>

    <add-edit-agent
      v-if="showAgentPopup"
      :show="showAgentPopup"
      :agent-data="{}"
      :inbox-list="populateInboxData"
      @onClose="() => (showAgentPopup = false)"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';
import DataTable from 'dashboard/components/widgets/table/DataTable';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import AddEditAgent from 'dashboard/routes/dashboard/settings/agents/AddEditAgent';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import inboxMixin, { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import alertMixin from 'shared/mixins/alertMixin';

import * as types from 'shared/constants/googleEventType';
import router from 'dashboard/routes/index';

export default {
  components: {
    PageHeader,
    SearchBox,
    DataTable,
    AddEditAgent,
    ReferenceBox,
  },
  mixins: [
    inboxMixin,
    googleAnalyticsMixin,
    alertMixin,
    mixPanelAnalyticsMixin,
  ],
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      inboxMembers: [],
      inboxMembersComment: [],
      channelAgents: [],
      isFetchingMembers: false,
      isUpdating: false,
      showCommentCollaborators: false,
      showAgentPopup: false,
      tableColumns: [
        {
          prop: 'avatar',
          component: 'AgentAvatar',
          width: '10%',
        },
        {
          prop: 'name',
          component: 'AgentNameWithStatus',
          width: '35%',
        },
        {
          component: 'AgentRoleAndEmail',
          width: '30%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      records: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
    }),
    showChannelAgentListBanner() {
      return this.isInboxUserListAvailable;
    },
    channelAgentListBannerContent() {
      if (!this.showChannelAgentListBanner) return '';
      if (this.channelType === INBOX_TYPES.KNOWLARITY) {
        return this.$t('INBOX_MGMT.BANNER.AGENTS_LIST_ONLY_KNOWLARITY_IDS');
      }
      return '';
    },
    isNewPage() {
      return this.$route.params.page === 'new';
    },
    currentMembers() {
      return this.showCommentCollaborators
        ? this.inboxMembersComment
        : this.inboxMembers;
    },
    currentInboxType() {
      return this.showCommentCollaborators ? 'comment' : 'message';
    },
    addedCollaborators() {
      return this.filterAgentsBySearch(this.currentMembers).map(agent => ({
        ...agent,
        shouldHaveWebRTCId: this.isInboxUserListAvailable,
      }));
    },
    notAddedCollaborators() {
      const members = this.currentMembers;

      let collaborators = this.records.filter(
        agent => !members.some(member => member.id === agent.id)
      );

      collaborators = this.filterAgentsBySearch(collaborators);

      if (this.isInboxUserListAvailable) {
        const channelAgentsEmails = new Set(
          this.channelAgents.map(agent => agent.email)
        );
        collaborators = collaborators
          .filter(agent => channelAgentsEmails.has(agent.email))
          .map(agent => ({ ...agent, shouldHaveWebRTCId: true }));
      }

      return collaborators;
    },
    populateInboxData() {
      const { inbox } = this;

      if (!inbox.id) return [];

      return [
        {
          id: inbox.id,
          name: inbox.name || this.channelName,
          channel_type: inbox.channel_type,
        },
      ];
    },
  },
  created() {
    if (!this.records.length) this.$store.dispatch('agents/get');
    this.fetchInboxMembers();
  },
  methods: {
    // Search related
    setSearch(value) {
      this.search = value;
    },
    filterAgentsBySearch(agents) {
      const searchLower = this.search.toLowerCase();
      return agents.filter(
        agent =>
          agent.name.toLowerCase().includes(searchLower) ||
          agent.role.toLowerCase().includes(searchLower)
      );
    },

    // Member management
    addToCurrentMembers(agent) {
      if (this.showCommentCollaborators) {
        this.inboxMembersComment.push(agent);
      } else {
        this.inboxMembers.push(agent);
      }
    },
    removeFromCurrentMembers(index) {
      if (this.showCommentCollaborators) {
        this.inboxMembersComment.splice(index, 1);
      } else {
        this.inboxMembers.splice(index, 1);
      }
    },
    onAddCollaborator(agent) {
      const eventType = this.getAnalyticsEventType('add');
      this.googleAnalyticsEvent(eventType, types.default.SETTINGS_INBOX, agent);
      this.addToCurrentMembers(agent);
    },
    onRemoveMember({ index }) {
      const eventType = this.getAnalyticsEventType('remove');
      this.googleAnalyticsEvent(
        eventType,
        types.default.SETTINGS_INBOX,
        types.default.SETTINGS_INBOX
      );
      this.removeFromCurrentMembers(index);
    },
    openAddPopup() {
      this.showAgentPopup = true;
    },

    // API calls
    async fetchInboxMembers() {
      this.isFetchingMembers = true;
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.inbox.id,
        });

        const {
          data: { payload: inboxMembers },
        } = response;

        this.inboxMembers = inboxMembers.filter(
          item => item.inbox_type === 'message'
        );
        this.inboxMembersComment = inboxMembers.filter(
          item => item.inbox_type === 'comment'
        );

        if (this.isInboxUserListAvailable) {
          await this.fetchChannelAgents();
          this.filterMembersByChannelAgents();
        }
        this.isFetchingMembers = false;
      } catch (error) {
        this.isFetchingMembers = false;
        this.showAlert(
          this.$t('INBOX_MGMT.AGENTS.FETCH_INBOX_COLLABORATORS_ERROR'),
          'error'
        );
      }
    },
    async fetchChannelAgents() {
      try {
        const response = await this.$store.dispatch(
          'inboxes/getChannelAgents',
          {
            channelId: this.inbox.channel_id,
            channelType: this.inbox.channel_type,
          }
        );
        this.channelAgents = response;
      } catch (error) {
        this.channelAgents = [];
        this.showAlert(
          this.$t('INBOX_MGMT.AGENTS.FETCH_CHANNEL_AGENTS_ERROR'),
          'error'
        );
      }
    },
    filterMembersByChannelAgents() {
      const channelAgentsEmails = new Set(
        this.channelAgents.map(agent => agent.email)
      );
      this.inboxMembers = this.inboxMembers.filter(agent =>
        channelAgentsEmails.has(agent.email)
      );
      this.inboxMembersComment = this.inboxMembersComment.filter(agent =>
        channelAgentsEmails.has(agent.email)
      );
    },
    async updateAgents() {
      this.googleAnalyticsEvent(
        types.default.INBOX_UPDATE_AGENTS,
        types.default.SETTINGS_INBOX,
        types.default.SETTINGS_INBOX
      );
      this.isUpdating = true;
      try {
        const agentList = this.inboxMembers.map(el => el.id);
        const agentListComment = this.inboxMembersComment.map(el => el.id);

        if (this.isAFacebookInbox) {
          await this.$store.dispatch('inboxMembers/create', {
            inboxId: this.inbox.id,
            agentList: agentList.concat(agentListComment),
            inboxType: 'comment',
          });
        } else {
          await this.$store.dispatch('inboxMembers/create', {
            inboxId: this.inbox.id,
            agentList,
            inboxType: 'message',
          });
        }

        if (this.isAKnowlarityInbox) {
          await this.$store.dispatch('syncKnowlarityAgents', {
            channelId: this.inbox.channel_id,
            body: {
              user_ids: agentList,
            },
          });
        }

        this.isUpdating = false;

        if (this.isNewPage)
          router.replace({
            name: 'settings_inbox_finish',
            params: {
              page: 'new',
              inbox_id: this.$route.params.inbox_id,
            },
          });

        this.showAlert(
          this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.isUpdating = false;
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'), 'error');
      }
    },

    // Analytics
    getAnalyticsEventType(action) {
      if (this.showCommentCollaborators) {
        return action === 'add'
          ? types.default.INBOX_ON_COMMENT_SELECT
          : types.default.INBOX_ON_REMOVE_COMMENT;
      }
      return action === 'add'
        ? types.default.INBOX_ON_AGENT_SELECT
        : types.default.INBOX_ON_REMOVE_AGENT;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.reference-box {
  max-width: 100%;
}

.custom-button {
  width: $space-slab * 10;
}

.search-container {
  background-color: $neutral-white;
  height: $space-four * 2;
  position: sticky;
  top: $zero;
  z-index: 1;
}

.search-box {
  flex-direction: row-reverse;
  margin-bottom: $zero;
  width: 82.6%;
}

.bg-green {
  ::v-deep {
    tr {
      background-color: $bg-green;

      td {
        border-top: $space-small solid $neutral-white;
      }
    }
  }
}

.bg-white {
  ::v-deep {
    tr {
      background-color: $neutral-white;

      td {
        border-top: $space-small solid $neutral-white;
      }
    }
  }
}
</style>
