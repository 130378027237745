import { mapGetters } from 'vuex';
import { CALL_PROVIDERS } from 'dashboard/constants';

export default {
  computed: {
    ...mapGetters({
      knowlarityInboxes: 'inboxes/getKnowlarityInboxes',
      exotelInboxes: 'inboxes/getExotelInboxes',
      currentUser: 'getCurrentUser',
    }),
    getAvailableKnowlarityInboxes() {
      return this.knowlarityInboxes.filter(inbox =>
        this.currentUser.call_function.includes(inbox.id)
      );
    },
    getAvailableExotelInboxes() {
      return this.exotelInboxes.filter(inbox =>
        this.currentUser.call_function.includes(inbox.id)
      );
    },
    getAvailableVoiceInboxes() {
      const knowlarityMapped = this.getAvailableKnowlarityInboxes;
      const exotelMapped = this.getAvailableExotelInboxes;

      return [...knowlarityMapped, ...exotelMapped];
    },
    hasVoiceCallInboxes() {
      return this.knowlarityInboxes.length || this.exotelInboxes.length;
    },
    isCallFeatureAvailable() {
      return this.getAvailableVoiceInboxes.length > 0;
    },
  },
  methods: {
    createCallInstance({
      agentNumber,
      agentRole,
      agentName,
      customerNumber = '',
      customerName = null,
      chat = null,
      activeInbox = null,
    }) {
      bus.$emit('initiateCallInstance', {
        agentNumber,
        agentRole,
        agentName,
        customerNumber,
        customerName,
        chat,
        activeInbox,
      });
    },
    getUserAccessibleInboxes(inboxes, provider) {
      return inboxes
        .filter(({ id }) => this.currentUser.call_function.includes(id))
        .map(inbox => {
          switch (provider) {
            case CALL_PROVIDERS.KNOWLARITY:
              return {
                id: inbox.channel_id,
                name: `${inbox.name} (${inbox.sr_number})`,
                callerId: inbox.caller_id,
                knowlarityNumber: inbox.sr_number,
                provider,
                isWebRTC: inbox.is_web_rtc,
              };
            case CALL_PROVIDERS.EXOTEL:
              return {
                id: inbox.id,
                name: `${inbox.name}`,
                virtual_numbers: inbox.virtual_numbers,
                provider,
                isWebRTC: inbox.is_web_rtc,
              };
            default:
              return {
                id: inbox.channel_id,
                name: `${inbox.name}`,
                provider,
                isWebRTC: inbox.is_web_rtc,
              };
          }
        });
    },
  },
};
