<template>
  <div class="flex-row-justify-center full-height">
    <onboarding-tour
      v-if="showProgressTrackerTour"
      :tour-name="TOUR_NAME"
      :tour-version="progressTrackerTourSteps.TOUR_VERSION"
      :tour-steps="progressTrackerTourSteps.STEPS"
      @unmountTour="onCloseTour"
    />
    <aside class="sidebar-container animated shrink sidebar-container--close">
      <div class="sidebar columns">
        <a class="logo" :href="dashboardPath">
          <icons
            name="lime"
            color="white"
            :custom-style="{ width: '4.4rem' }"
          />
        </a>

        <div class="main-nav">
          <transition-group name="menu-list" tag="ul" class="menu vertical">
            <navbar-item
              v-for="item in accessibleMenuItems"
              :key="item.toStateName"
              :menu-item="item"
            />
          </transition-group>
        </div>
        <div class="bottom-nav flex-column flex-align flex-justify gap--two">
          <call-button
            :icon-size="'mediumlarge'"
            :span-class="{ 'bottom-nav-item': true }"
            :tooltip-msg="tooltipMsg"
            :call-icon-color="callIconColor"
            @showCallPopup="onShowCallPopup"
          />
          <woot-base-button
            v-if="showProgressTrackerButton"
            tag="span"
            size="small"
            variant="tertiary"
            class="progress-tracker-button bottom-nav-item"
            @click="onShowProgressTrackerModal"
          >
            <icons
              name="filterSquircle"
              color="white"
              size="medium"
              view="4 4 24 24"
              :show-title="false"
            />
            <div class="statbadge statbadge-dot statbadge--danger" />
          </woot-base-button>
          <notification-bell class="bottom-nav-item" />
          <email-confirmation-status v-if="!isCurrentUserConfirmed" />
          <div
            class="content-margin bottom-avatar"
            @click.prevent="showBottomNavOptions"
          >
            <thumbnail
              :colored-avatar="true"
              :src="currentUser.avatar_url"
              size="48px"
              :username="currentUserAvailableName"
            />
            <div
              :class="
                `statbadge statbadge-dot statbadge__${currentUser.availability_status}`
              "
            />
          </div>
          <transition name="menu-slide">
            <div
              v-if="showBottomNavMenu"
              v-on-clickaway="showBottomNavOptions"
              class="dropdown-pane bottom-pane-menu top"
            >
              <div class="availibility-container">
                <availability-status />
              </div>

              <ul class="vertical dropdown menu">
                <li class="text-dark title-h5" style="color: #3c492c">
                  <span>
                    <router-link
                      class="text-dark"
                      :to="`/app/accounts/${accountId}/profile`"
                      @click.native="inlineButtonClickHandler"
                    >
                      {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
                    </router-link>
                  </span>
                </li>

                <div class="text-dark title-h5 flex-space-between">
                  <span>
                    <router-link :to="`#`" class="download-text">
                      {{ $t('SIDEBAR_ITEMS.DOWNLOAD_APPS') }}
                    </router-link>
                  </span>

                  <div
                    class="text-dark title-h5 flex-space-between download-icon-container"
                  >
                    <icons
                      name="apple"
                      :color="appleIconHover ? 'green' : 'darkestdarkgrey'"
                      size="medium"
                      :show-title="true"
                      class="apple-icon"
                      cursor="pointer"
                      @mouseover="appleIconHover = true"
                      @mouseleave="appleIconHover = false"
                      @click="gotoAppStore()"
                    />

                    <icons
                      name="android"
                      :color="androidIconHover ? 'green' : 'darkestdarkgrey'"
                      size="medium"
                      :show-title="true"
                      cursor="pointer"
                      @mouseover="androidIconHover = true"
                      @mouseleave="androidIconHover = false"
                      @click="gotoPlayStore()"
                    />
                  </div>
                </div>

                <li class="title-h5 text-dark">
                  <a href="#" style="color: #ff7e61" @click.prevent="logout()">
                    {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
                  </a>
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </aside>
    <div v-if="showSubMenu()" class="submenu flex-column custom-scroll">
      <component
        :is="item.isExternal ? 'a' : 'router-link'"
        v-for="item in currentMenuItem && currentMenuItem.children"
        :key="item.id"
        :to="!item.isExternal ? item.toState : null"
        :href="item.isExternal ? item.toState : null"
        :rel="item.isExternal ? 'noopener noreferrer' : null"
        :target="item.isExternal ? '_blank' : null"
        active-class="active-submenu"
      >
        <div
          class="submenu--label flex-row flex-align"
          @click="logClickEvent(item)"
        >
          <span class="submenu--label--bar" />
          <span v-if="item.icon" class="submenu--label--icon">
            <icons :name="item.icon" size="medium" color="darkgreen" />
          </span>
          <div style="position: relative">
            {{ $t(`SIDEBAR.${item.label}`) }}
            <span v-if="item.isNew" class="statbadge statbadge-new">
              NEW
            </span>
          </div>
        </div>
      </component>
    </div>
    <transition name="modal-fade">
      <progress-tracker-modal
        v-if="showProgressTrackerModal"
        @closeModal="showProgressTrackerModal = false"
      />
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { required, minLength } from 'vuelidate/lib/validators';

import NavbarItem from './NavbarItem';
import AvailabilityStatus from './AvailabilityStatus';
import EmailConfirmationStatus from './EmailConfirmationStatus';
import Thumbnail from '../widgets/Thumbnail';
import NotificationBell from './sidebarComponents/NotificationBell';
import ProgressTrackerModal from 'dashboard/components/ui/ProgressTrackerModal';
import OnboardingTour from 'dashboard/components/ui/tours/OnboardingTour';
import CallButton from 'dashboard/components/ui/calling/CallButton';

import shopifyMixin from 'dashboard/mixins/shopify';
import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import adminMixin from '../../mixins/isAdmin';
import onboardingTourMixin from 'dashboard/mixins/onboardingTour';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import callMixin from 'shared/mixins/callMixin';

import { getSidebarItems } from '../../i18n/default-sidebar';
import { frontendURL } from '../../helper/URLHelper';
import * as types from 'shared/constants/googleEventType';
const TOUR_NAME = 'progressTrackerTour';

export default {
  components: {
    NavbarItem,
    Thumbnail,
    AvailabilityStatus,
    EmailConfirmationStatus,
    NotificationBell,
    ProgressTrackerModal,
    OnboardingTour,
    CallButton,
  },
  mixins: [
    clickaway,
    adminMixin,
    alertMixin,
    shopifyMixin,
    onboardingTourMixin,
    googleAnalyticsMixin,
    mixPanelAnalyticsMixin,
    callMixin,
  ],
  props: {
    route: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showBottomNavMenu: false,
      showAccountModal: false,
      currentMenuItem: null,
      appleIconHover: false,
      androidIconHover: false,
      isFetchingData: true,
      showProgressTrackerModal: false,
      showProgressTrackerTour: false,
      accountName: '',
      TOUR_NAME,
    };
  },
  validations: {
    accountName: {
      required,
      minLength: minLength(1),
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
      getAccount: 'accounts/getAccount',
      accountDetails: 'accounts/getAccountDetails',
      agents: 'agents/getAgents',
      inboxes: 'inboxes/getInboxes',
      templates: 'getTemplates',
      broadcastList: 'getBroadcastList',
      currentUserRole: 'getCurrentRole',
      callStatesFlags: 'getCallStatesUiFlags',
      currentCallState: 'getCurrentCallState',
    }),
    showProgressTrackerButton() {
      if (this.isFetchingData) return false;

      const canShow =
        this.isAdmin &&
        (!this.inboxes.length ||
          this.agents.length < 2 ||
          !this.templates.length ||
          !this.broadcastList);
      return canShow;
    },
    currentUserAvailableName() {
      return this.currentUser.name;
    },
    isCurrentUserConfirmed() {
      const auth0User = this.$auth.user;

      return auth0User.email_verified || this.currentUser.confirmed;
    },
    sidemenuItems() {
      return getSidebarItems(this.accountId);
    },
    accessibleMenuItems() {
      let menuItems = [];
      const groupItem = this.sidemenuItems.common;
      const isRouteIncluded = groupItem.routes.includes(this.currentRoute);
      if (isRouteIncluded) {
        menuItems = Object.values(groupItem.menuItems);
      }

      return this.filterMenuItemsByRole(menuItems);
    },
    currentRoute() {
      return this.$store.state.route.name;
    },
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
    showTourOnlyOnce() {
      return (
        localStorage.getItem(TOUR_NAME) !==
        this.progressTrackerTourSteps.TOUR_VERSION
      );
    },
    eventName() {
      return this.currentCallState.eventName;
    },
    tooltipMsg() {
      if (!this.isCallFeatureAvailable)
        return 'You do not have enough permissions to perform this action. Please contact an administrator';
      return null;
    },
    callIconColor() {
      if (!this.isCallFeatureAvailable) return 'lightgrey-fill-only';
      if (this.callStatesFlags.showCallPopup) return 'white';
      return 'white';
    },
  },
  created() {
    axios.all([
      this.$store.dispatch('labels/getLabelsWithoutGroups'),
      this.$store.dispatch('labels/getLabelsWithoutGroups', {
        resource_type: 'Contact',
      }),
      this.$store.dispatch('labels/get', 'Conversation'),
      this.$store.dispatch('labels/get', 'Contact'),
      this.$store.dispatch('teams/get'),
    ]);

    if (this.isAdmin)
      axios
        .all([
          this.$store.dispatch('inboxes/get'),
          this.$store.dispatch('agents/get'),
          this.$store.dispatch('getTemplate'),
          this.$store.dispatch('broadcastStatus', { page: 1 }),
        ])
        .then(() => {
          this.isFetchingData = false;
          this.initTour();
        });
    else this.$store.dispatch('inboxes/get');
  },
  methods: {
    initTour() {
      if (this.showProgressTrackerButton && this.showTourOnlyOnce) {
        this.showProgressTrackerTour = true;

        if (
          !Object.keys(this.$tours).length &&
          !localStorage.getItem('firstLaunch')
        )
          this.$nextTick(() => {
            this.$tours[TOUR_NAME].start();
          });
      }
    },
    onCloseTour() {
      this.onShowProgressTrackerModal();
    },
    onShowProgressTrackerModal() {
      this.showProgressTrackerModal = true;
    },
    showSubMenu() {
      if (this.currentRole === 'agent') {
        this.currentMenuItem = null;
        return false;
      }
      const currentPath = this.$router.history.current.path;
      const pathMap = {
        profile: null,
        analytics: 'analytics',
        settings: 'settings',
        broadcast: 'broadcast',
      };

      const key = Object.keys(pathMap).find(pathKey =>
        currentPath.includes(pathKey)
      );

      if (key) {
        this.currentMenuItem = pathMap[key]
          ? this.accessibleMenuItems.find(
              item => item.label.toLowerCase() === pathMap[key]
            )
          : null;
        return pathMap[key] !== null;
      }
      this.currentMenuItem = null;
      return false;
    },
    filterMenuItemsByRole(menuItems) {
      if (!this.currentRole) return [];

      return menuItems.filter(menuItem => {
        if (menuItem.children) {
          if (menuItem.toStateName === 'settings_inbox_list') {
            if (this.disableBilling(this.accountDetails)) {
              menuItem.children = menuItem.children.filter(
                child => child.id !== 'settings_billing_plan'
              );
            }
          }
          menuItem.children = menuItem.children.filter(menuItemChild => {
            return (
              window.roleWiseRoutes[this.currentRole].indexOf(
                menuItemChild.toStateName
              ) > -1
            );
          });
        }
        return (
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
        );
      });
    },
    logout() {
      this.$store.dispatch('logout');
    },
    showBottomNavOptions() {
      this.showBottomNavMenu = !this.showBottomNavMenu;
      if (this.showBottomNavMenu) {
        this.mix_panel_clicked_side_nav_menu_item('PROFILE');
      }
    },
    changeAccount() {
      this.showAccountModal = true;
    },
    onClose() {
      this.showAccountModal = false;
    },
    createAccount() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    gotoAppStore() {
      this.googleAnalyticsEvent(
        types.default.NAVBAR_APP_STORE,
        types.default.TEMPLATE_SETTINGS,
        types.default.NAVBAR_APP_STORE
      );
      window.open(
        'https://apps.apple.com/us/app/limechat-agent/id1579651271',
        '_blank',
        'noopener'
      );
    },
    gotoPlayStore() {
      this.googleAnalyticsEvent(
        types.default.NAVBAR_GOOGLE_PLAY,
        types.default.TEMPLATE_SETTINGS,
        types.default.NAVBAR_GOOGLE_PLAY
      );

      window.open(
        'https://play.google.com/store/apps/details?id=com.limechat.app',
        '_blank',
        'noopener'
      );
    },
    logClickEvent(item) {
      if (item.toState.includes('settings')) {
        this.mix_panel_clicked_settings_section(item.label);
      }
      if (item.toState.includes('analytics')) {
        this.mix_panel_clicked_analytics_section(item.toStateName);
      }
    },
    inlineButtonClickHandler() {
      this.mix_panel_clicked_profile_settings();
    },
    onShowCallPopup() {
      this.mix_panel_clicked_side_nav_menu_item('CALLS');
      if (
        !this.isCallFeatureAvailable ||
        this.callStatesFlags.showCallNotification
      )
        return;
      if (this.eventName === 'HANGUP' || this.eventName === '') {
        const {
          phone_number: agentNumber,
          role: agentRole,
          available_name: agentName,
        } = this.currentUser;

        this.createCallInstance({
          agentNumber,
          agentRole,
          agentName,
        });
      }

      this.$store.dispatch(
        'showCallPopup',
        !this.callStatesFlags.showCallPopup
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.progress-tracker-button {
  position: relative;
}

.statbadge-dot {
  top: $zero;
  right: $zero;
}

.account-header {
  padding-left: $space-normal;
}
.account-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $space-normal;
  padding-left: $zero;
  border-bottom: 1px solid lightgray;
}
.account-selector--modal {
  .modal-container {
    width: 32rem;
  }

  .page-top-bar {
    padding-bottom: $space-two;
  }
}

.account-popup {
  padding: $space-normal;
  left: 107%;
  bottom: 6rem;
}

.bottom-pane-menu {
  bottom: 9rem;
  left: $zero !important;
}
.change-accounts--button.button {
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  padding: $space-small $space-one;
  cursor: pointer;
}

.selected-account {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ion-ios-checkmark {
  font-size: $font-size-big;
  color: $pg-1-500;
}

.corner-slide {
  &-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  &-fade-leave-active {
    transition: all 0.2s ease-in;
  }

  &-fade-enter,
  &-fade-leave-to {
    transform: translate(-$space-four * 2, -$space-four * 2) scale(0.5);
    opacity: $zero;
  }

  &-fade-enter-to,
  &-fade-leave {
    opacity: 1;
  }
}

.download-icon-container {
  margin-right: $space-slab;
}

.download-text {
  color: $text-dark;
  pointer-events: none;
}

.apple-icon {
  margin-right: $space-micro;
}
</style>
