/**
 * Knowlarity Widget Plugin
 *
 * This plugin provides a customizable embedded widget that integrates voice calling and communication functionality
 * from Knowlarity into web pages. The widget includes a draggable interface, toggleable content display, and an iframe
 * to load external content. It allows interaction with the Knowlarity platform to initiate calls, manage user interactions,
 * and configure widget settings.
 *
 * Key Features:
 * - Draggable widget with support for both touch and mouse events.
 * - Customizable iframe (height, width, and source URL).
 * - Toggleable display of widget content.
 * - Interaction with the Knowlarity platform for initiating calls.
 *
 * Necessity of Explicit Dragging Logic:
 * The default draggable functionality in Knowlarity's widget is broken and doesn't work reliably. As a result,
 * we have implemented a custom dragging mechanism to ensure:
 * - **Smooth dragging**: The widget can be moved reliably across the screen using both mouse and touch events.
 * This custom solution ensures a better, more consistent user experience.
 */

const WIDGET_CONFIG = {
  script: 'https://konnect.knowlarity.com/external/embed/widget.js',
  defaultConfig: {
    height: '550px',
    width: '400px',
    src: 'https://konnect.knowlarity.com/external/#',
  },
};

function createWidgetUI() {
  // Create main widget container
  const widgetMain = document.createElement('div');
  widgetMain.setAttribute('id', 'knw_widget_main');
  widgetMain.style.cssText = `
      position: fixed;
      bottom: 8rem;
      right: 1rem;
      z-index: 100000;
    `;

  // Create widget header
  const widgetHeader = document.createElement('div');
  widgetHeader.setAttribute('class', 'knw_widget_header');
  widgetHeader.style.cssText = `
      position: relative;
      display: block;
      top: 0;
      left: 0;
      height: 64px;
      width: 64px;
      cursor: pointer;
    `;

  // Create widget content container
  const widgetContent = document.createElement('div');
  widgetContent.setAttribute('class', 'knw_widget_content');
  widgetContent.style.cssText = `
      margin: 0;
      padding: 0;
      display: none;
      position: fixed;
      bottom: 0;
      right: 0;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1);
      border-radius: 4px;
      overflow: hidden;
      touch-action: none;
      will-change: transform;
      z-index: 100001;
    `;

  // Create content header
  const contentHeader = document.createElement('div');
  contentHeader.setAttribute('class', 'content_header');
  contentHeader.style.cssText = `
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: linear-gradient(277deg, rgba(134,46,171,0.9864320728291317) 28%, rgba(70,88,187,0.9864320728291317) 100%);
      cursor: move;
      user-select: none;
      touch-action: none;
    `;

  // Add a title/handle for dragging
  const dragHandle = document.createElement('div');
  dragHandle.textContent = 'VoiceXcel';
  dragHandle.style.cssText = `
      color: white;
      font-weight: 500;
      padding-left: 1rem;
      flex: 1;
    `;
  contentHeader.appendChild(dragHandle);

  // Create close button
  const closeImg = document.createElement('img');
  closeImg.src = `${
    WIDGET_CONFIG.defaultConfig.src.split('#')[0]
  }embed/close.png`;
  closeImg.title = 'Close';
  closeImg.alt = 'Close';
  closeImg.style.cssText =
    'height: 50%; padding-right: 0.5rem; cursor: pointer;';
  contentHeader.appendChild(closeImg);

  // Create frame content
  const frameContent = document.createElement('div');
  frameContent.setAttribute('class', 'frameContent');
  frameContent.style.cssText = 'display: flex; border: none;';

  // Create iframe
  const iframe = document.createElement('iframe');
  iframe.setAttribute('allow', 'microphone');
  iframe.setAttribute('id', 'knw_widget_frame');
  iframe.setAttribute('frameborder', '0');
  frameContent.appendChild(iframe);
  widgetContent.appendChild(contentHeader);
  widgetContent.appendChild(frameContent);

  // Assemble widget
  widgetMain.appendChild(widgetHeader);
  widgetMain.appendChild(widgetContent);

  // Dragging functionality
  let isDragging = false;
  let currentX;
  let currentY;
  let initialX;
  let initialY;
  let xOffset = 0;
  let yOffset = 0;

  function setTranslate(xPos, yPos, el) {
    el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
  }

  function dragStart(e) {
    if (e.type === 'touchstart') {
      initialX = e.touches[0].clientX - xOffset;
      initialY = e.touches[0].clientY - yOffset;
    } else {
      initialX = e.clientX - xOffset;
      initialY = e.clientY - yOffset;
    }

    if (e.target === dragHandle || e.target === contentHeader) {
      isDragging = true;
    }
  }

  function dragEnd() {
    initialX = currentX;
    initialY = currentY;
    isDragging = false;
  }

  function drag(e) {
    if (isDragging) {
      e.preventDefault();

      if (e.type === 'touchmove') {
        currentX = e.touches[0].clientX - initialX;
        currentY = e.touches[0].clientY - initialY;
      } else {
        currentX = e.clientX - initialX;
        currentY = e.clientY - initialY;
      }

      xOffset = currentX;
      yOffset = currentY;

      setTranslate(currentX, currentY, widgetContent);
    }
  }

  // Add drag event listeners
  contentHeader.addEventListener('touchstart', dragStart, false);
  contentHeader.addEventListener('touchend', dragEnd, false);
  contentHeader.addEventListener('touchmove', drag, false);
  contentHeader.addEventListener('mousedown', dragStart, false);
  document.addEventListener('mousemove', drag, false);
  document.addEventListener('mouseup', dragEnd, false);

  // Toggle functionality
  function toggleWidget(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const header = widgetHeader;
    const content = widgetContent;

    if (header.style.display === 'none') {
      header.style.display = 'block';
    } else {
      header.style.display = 'none';
    }

    if (content.style.display === 'flex') {
      content.style.display = 'none';
    } else {
      content.style.display = 'flex';
    }
  }

  function openWidget(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const header = widgetHeader;
    const content = widgetContent;

    if (header.style.display === 'none') {
      header.style.display = 'block';
    }
    if (content.style.display === 'none') {
      content.style.display = 'flex';
    }
  }

  closeImg.addEventListener('click', toggleWidget);

  return { widgetMain, iframe, toggleWidget, openWidget };
}

export const KnowlarityWidget = {
  install(Vue) {
    let widgetConfig = { ...WIDGET_CONFIG.defaultConfig };
    let initializationPromise = null;
    let widgetElements = null;

    const initializeWidget = () => {
      return new Promise((resolve, reject) => {
        try {
          // Create UI elements if not already created
          if (!widgetElements) {
            widgetElements = createWidgetUI();
            document.body.appendChild(widgetElements.widgetMain);
          }

          // Set up iframe
          widgetElements.iframe.style.height = widgetConfig.height;
          widgetElements.iframe.style.width = widgetConfig.width;
          widgetElements.iframe.src = widgetConfig.src;

          // Define the setConfig function that will be called by widget.js
          window.setConfig = function() {
            if (window.KNW_WIDGET) {
              window.KNW_WIDGET.setUserConfig(widgetConfig);
            } else {
              reject(new Error('KNW_WIDGET not found after initialization'));
            }
          };

          // Check if script already exists, and if not, create and append it
          const existingScript = document.getElementById('knw-widget-sdk');
          if (existingScript) {
            existingScript.remove();
          }

          const script = document.createElement('script');
          script.id = 'knw-widget-sdk';
          script.src = WIDGET_CONFIG.script;
          script.async = true;
          script.onerror = () => {
            reject(new Error('Failed to load widget script'));
          };
          script.onload = () => {
            resolve();
          };
          document.body.appendChild(script);
        } catch (error) {
          reject(error);
        }
      });
    };

    Vue.prototype.$knowlarity = {
      setConfig(config) {
        widgetConfig = { ...widgetConfig, ...config };
        if (window.KNW_WIDGET) {
          window.KNW_WIDGET.setUserConfig(widgetConfig);
        }
      },

      async toggleWidget() {
        if (!this.isInitialized()) {
          throw new Error('Widget not initialized');
        }
        widgetElements.toggleWidget();
      },

      async openWidget() {
        if (!this.isInitialized()) {
          throw new Error('Widget not initialized');
        }
        widgetElements.openWidget();
      },

      async initiateCall(phoneNumber) {
        try {
          await this.ensureInitialized();
          if (!this.isInitialized()) {
            throw new Error('Widget not initialized');
          }

          // Ensure widget is visible before making the call
          const content = document.querySelector('.knw_widget_content');
          if (content && content.style.display === 'none') {
            this.toggleWidget();
          }

          return window.KNW_WIDGET.sendMessage('c2c_call', {
            customer_number: phoneNumber,
          });
        } catch (error) {
          throw new Error(error);
        }
      },

      isInitialized() {
        return window.KNW_WIDGET;
      },
      ensureInitialized() {
        if (this.isInitialized()) {
          return Promise.resolve();
        }

        if (!initializationPromise) {
          initializationPromise = initializeWidget();
        }

        return initializationPromise;
      },
    };
  },
};
