<template>
  <div class="small-12 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.DESC')"
    >
      <reference-box
        :to="documentation.channels.voice.knowlarity"
        content="Know more about integrating knowlarity"
        type="document"
      />
    </page-header>
    <woot-loading-state
      v-if="uiFlags.isCreating"
      :message="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.LOADING_MESSAGE')"
    />
    <form v-if="!uiFlags.isCreating" @submit.prevent="createChannel">
      <div class="settings-sub-menu-content--larger mg-bottom--jumbo">
        <woot-input
          v-model.trim="inboxName"
          required
          auto-focus
          class="medium-12 columns content-margin"
          :class="{ error: $v.inboxName.$error }"
          :has-error="$v.inboxName.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
          "
          @blur="$v.inboxName.$touch"
        />
        <woot-input
          v-model.trim="apiKey"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.apiKey.$error }"
          :has-error="$v.apiKey.$error"
          :error="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.ERROR')"
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.LABEL')"
          :placeholder="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKey.$touch"
        />
        <woot-input
          v-model.trim="srNumber"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.srNumber.$error }"
          :has-error="$v.srNumber.$error"
          :error="
            srNumber === ''
              ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
              : $t('INBOX_MGMT.ADD.VOICE_CHANNEL.SR_NUMBER.ERROR')
          "
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.SR_NUMBER.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.SR_NUMBER.PLACEHOLDER')
          "
          @blur="$v.srNumber.$touch"
        />
        <woot-input
          v-model.trim="callerId"
          required
          class="medium-12 columns content-margin"
          :class="{ error: $v.callerId.$error }"
          :has-error="$v.callerId.$error"
          :error="
            callerId === ''
              ? $t('INBOX_MGMT.COMMON_ERROR_MESSAGE')
              : $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLER_ID.ERROR')
          "
          :label="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLER_ID.LABEL')"
          :placeholder="
            $t('INBOX_MGMT.ADD.VOICE_CHANNEL.CALLER_ID.PLACEHOLDER')
          "
          @blur="$v.callerId.$touch"
        />
        <div style="cursor:pointer">
          <label class="container-checkbox">
            <input v-model="isWebRTC" type="checkbox" />
            <span class="checkmark" />
            <span
              class="body-b3 text-dark"
              v-text="$t('INBOX_MGMT.ADD.IS_WEB_RTC.TITLE')"
            />
          </label>
        </div>
      </div>
      <div class="medium-12 columns settings-sub-menu-bottom-nav">
        <woot-base-button
          variant="secondary"
          @click.prevent="() => $router.go(-1)"
        >
          Back
        </woot-base-button>
        <woot-base-button
          :loading="uiFlags.isCreating"
          :disabled="$v.$invalid"
          type="submit"
        >
          Next
        </woot-base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import validations from '../validations';

import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import ReferenceBox from 'dashboard/components/ui/ReferenceBox';

import alertMixin from 'shared/mixins/alertMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import documentation from 'dashboard/helper/data/documentationData.json';

export default {
  components: {
    PageHeader,
    ReferenceBox,
  },
  mixins: [alertMixin, mixPanelAnalyticsMixin],
  data() {
    return {
      inboxName: '',
      apiKey: '',
      srNumber: '',
      callerId: '',
      documentation,
      isWebRTC: false,
    };
  },
  validations: {
    inboxName: { required },
    apiKey: { required },
    srNumber: { ...validations.phone, required },
    callerId: { ...validations.phone, required },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    enableList() {
      return this.$t('INBOX_MGMT.ADD.SELECTOR_ENABLE').map(item => {
        return {
          name: item.NAME,
          id: item.NAME,
          value: item.VALUE,
        };
      });
    },
  },
  methods: {
    getEnableText(val) {
      return val
        ? this.$t('INBOX_MGMT.ADD.SELECTOR_ENABLE[0].NAME')
        : this.$t('INBOX_MGMT.ADD.SELECTOR_ENABLE[1].NAME');
    },
    changeEnableGreeting(val) {
      this.greetingEnabled = val.value;
    },
    async createChannel() {
      const channel = await this.$store
        .dispatch('inboxes/createKnowlarityChannel', {
          name: this.inboxName,
          sr_api_key: this.apiKey,
          sr_number: '+' + this.srNumber,
          caller_id: '+' + this.callerId,
          account_id: this.accountId,
          is_web_rtc: this.isWebRTC,
        })
        .catch(error => {
          this.showAlert(
            this.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.ERROR.FAIL_INBOX_CREATION'),
            'error'
          );
          // sr number already in use
          if (
            error.response.data.error.includes(
              'key value violates unique constraint'
            )
          )
            this.showAlert(
              this.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.ERROR.DUPLICATE_SR_NUMBER'),
              'error',
              5000
            );
        });

      const {
        id: inbox_id,
        channel_type,
        name: channel_name,
        channel_id,
      } = channel;
      this.mix_panel_clicked_create_inbox_successful(
        'Voice Channel',
        'Knowlarity'
      );

      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id,
        },
        query: { channel_type, channel_name, channel_id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}

.content-margin {
  margin-bottom: 2.4rem;
}

.medium-12 {
  .button {
    float: right;
  }
}
.selector-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: $settings-searchbar-height;
  align-items: center;
  color: $neutral-grey-800;
  font-size: $font-size-small;
  line-height: 22px;
  background: $neutral-grey-200;
  border: 1px solid $neutral-grey-200;
  cursor: pointer;
  border-radius: $border-radius;
  padding: $space-slab;

  &:focus {
    border: 1px solid $pg-1-500;
    background: $neutral-white;
    outline: none;
  }

  &:hover {
    border: 1px solid $neutral-grey-600;
    background: $neutral-white;
  }
}
</style>
