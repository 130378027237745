/* eslint no-console: 0 */
/* eslint-env browser */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* Vue Core */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';

// Global Components
import hljs from 'highlight.js';
import Multiselect from 'vue-multiselect';
import WootSwitch from 'components/ui/Switch';
import WootWizard from 'components/ui/Wizard';
import Icons from 'components/ui/Icons';
import Selector from 'components/ui/Selector';
import TabNav from 'components/ui/TabNav';
import { sync } from 'vuex-router-sync';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';

import WootUiKit from '../dashboard/components';
import App from '../dashboard/App';
import i18n from '../dashboard/i18n';
import createApiInstance from '../dashboard/helper/APIHelper';
import commonHelpers from '../dashboard/helper/commons';
import router from '../dashboard/routes';
import store from '../dashboard/store';
import vueActionCable from '../dashboard/helper/actionCable';
import constants from '../dashboard/constants';
import { Auth0Plugin, auth0Configs } from '../dashboard/auth0';
import { KnowlarityWidget } from '../dashboard/plugins/knowlarity';
import {
  verifyServiceWorkerExistence,
  registerSubscription,
} from '../dashboard/helper/pushHelper';
import 'vue-easytable/libs/theme-default/index.css';
import VueGtag from 'vue-gtag';
import VueTour from 'vue-tour';
import VueTippy, { TippyComponent } from 'vue-tippy';
import 'vue-tour/dist/vue-tour.css';

Vue.config.env = process.env;

Vue.use(Auth0Plugin, {
  domain: auth0Configs.config.domain,
  clientId: auth0Configs.config.clientId,
  redirectUri: auth0Configs.config.redirectUri,
});
document.addEventListener('DOMContentLoaded', () => {
  Vue.use(KnowlarityWidget);
});

Vue.use(VueTour);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(WootUiKit);
Vue.use(Vuelidate);
Vue.use(VTooltip);
Vue.use(hljs.vuePlugin);
Vue.use(VueGtag, {
  config: { id: 'G-5M4TXK8D3C' },
});

Vue.component('multiselect', Multiselect);
Vue.component('woot-switch', WootSwitch);
Vue.component('woot-wizard', WootWizard);
Vue.component('icons', Icons);
Vue.component('selector', Selector);
Vue.component('tab-nav', TabNav);
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);
const i18nConfig = new VueI18n({
  locale: 'en',
  messages: i18n,
});

Vue.config.lang = 'en';
sync(store, router);
// load common helpers into js
commonHelpers();

Vue.config.errorHandler = (err, vm, info) => {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  console.log('Vue err is this, ', err);
  console.log('Vue vm is this, ', vm);
  console.log('Vue info is this, ', info);
};

window.WootConstants = constants;
window.axios = createApiInstance();
window.bus = new Vue();
window.onload = () => {
  window.WOOT = new Vue({
    router,
    store,
    i18n: i18nConfig,
    components: { App },
    template: '<App/>',
  }).$mount('#app');
  vueActionCable.init();
  mixpanel.init('4d427eba837c6c512f15753fca764f32');
  mixpanel.set_config({ persistence: 'localStorage' });
};

window.addEventListener('load', () => {
  verifyServiceWorkerExistence(registration =>
    registration.pushManager.getSubscription().then(subscription => {
      if (subscription) {
        registerSubscription();
      }
    })
  );
});
