<template>
  <div class="scroll--panel custom-scroll">
    <!-- Cancellation Modal -->
    <woot-modal :show.sync="showConfirmationModal" :on-close="onCancel">
      <woot-modal-header
        :header-title="$t('ORDER.CANCEL.TITLE') + ' - ' + selectedOrder.name"
        :header-content="$t('ORDER.CANCEL.CONFIRMATION')"
      />
      <div class="content-box">
        <label class="formlabel text-light">
          {{ $t('ORDER.CANCELLATION.TITLE') }}
        </label>
        <woot-single-selector
          variant="tertiary"
          size="large"
          :default-option="reasonForCancellation.name"
          :custom-button="true"
          :custom-style="{ width: '100%', left: '0.01rem' }"
          :option-list="reasonsForCancellation"
          @click="onSelectReason"
        >
          <div class="agent-dropdown-button content-margin flex-row flex-align">
            <span class="dropdown-option">
              {{ reasonForCancellation.name }}
            </span>
            <span style="margin-left: 0.8rem">
              <icons name="chevronDown" size="medium" color="green" />
            </span>
          </div>
        </woot-single-selector>
        <div
          v-if="
            permissions.canRefund &&
              (!selectedOrder.is_cod ||
                !selectedOrder.shopify_financial_status.status === 'pending')
          "
        >
          <!-- TODO: Add support for not sending notification to customer -->
          <div v-if="false" class="flex-row flex-align content-margin">
            <label
              style="cursor: pointer; padding-bottom: 0.8rem"
              class="container-checkbox"
            >
              <input
                v-model="shouldSendNotification"
                type="checkbox"
                :value="shouldSendNotification"
                @input="shouldSendNotification = !shouldSendNotification"
              />
              <span class="checkmark" />
            </label>
            <span class="text-dark">Send a notification to the customer</span>
          </div>
          <label class="formlabel text-light">
            {{ $t('ORDER.REFUND.TITLE_LIGHT') }}
          </label>
          <div class="flex-row flex-align content-margin">
            <div v-for="radioButton in refundingActions" :key="radioButton.id">
              <label
                :for="radioButton.id"
                class="radio-container"
                style="cursor: pointer"
              >
                <span class="body-b2"> {{ radioButton.name }}</span>
                <input
                  :id="radioButton.id"
                  v-model="shouldRefundNow"
                  type="radio"
                  name="refund-action"
                  :value="radioButton.value"
                />
                <span class="radio-circle" />
              </label>
            </div>
          </div>
          <woot-input
            v-if="shouldRefundNow"
            v-model.trim="refundAmt"
            class="refund-amt-input"
            :placeholder="$t('ORDER.REFUND.AMOUNT')"
          />
          <span
            v-if="refundAmt > maximumRefundableAmt && shouldRefundNow"
            class="capital_c4"
            :class="{ 'desc-error': true }"
          >
            Amount should not be more than order value
          </span>
        </div>
        <div v-else style="height: 16rem" />
        <div class="flex-row flex-justify--end">
          <woot-primary-button
            name="Yes, Cancel"
            :disabled="isCancelling"
            :loading="isCancelling"
            @click="onCancelOrder"
          />
        </div>
      </div>
    </woot-modal>

    <!-- Order Refund Modal -->
    <woot-modal
      :show.sync="showRefundModal"
      :on-close="onCancelRefundModal"
      :custom-style="{ 'min-height': '12rem' }"
    >
      <woot-modal-header
        :header-title="$t('ORDER.REFUND.TITLE')"
        :header-content="
          $t('ORDER.REFUND.INITIATE_REFUND', { orderName: selectedOrder.name })
        "
      />
      <span
        v-if="refundTransactionsLoading"
        class="content-box"
        v-text="'Please wait...'"
      />
      <div
        v-else-if="!selectedOrder.is_cod && refundTransactions"
        class="content-box"
      >
        <span
          class="text-dark"
          v-html="
            $t('ORDER.REFUND.MAXIUMUM_REFUND', {
              amount: refundTransactions[0].maximum_refundable,
            })
          "
        />
        <woot-input
          v-model.trim="refundAmt"
          :value="selectedOrder.maximum_refundable"
          :disabled="isEditedOrder"
          class="refund-amt-input"
          :label="$t('ORDER.REFUND.LABEL')"
          :placeholder="$t('ORDER.REFUND.AMOUNT')"
        />
        <span
          v-if="refundAmt > maximumRefundableAmt"
          class="capital_c3 desc-error"
          v-text="'Amount should not be more than order value'"
        />
        <div style="gap: 0.8rem" class="flex-row flex-justify--end">
          <woot-primary-button
            :name="$t('ORDER.REFUND.CONFIRMATION.REFUND')"
            color="red"
            :disabled="isRefunding || refundAmt > maximumRefundableAmt"
            :loading="isRefunding"
            @click="onRefundOrder"
          />
          <woot-secondary-button
            :name="$t('ORDER.REFUND.CONFIRMATION.CANCEL')"
            @click="onCancelRefundModal"
          />
        </div>
      </div>
      <div v-else class="content-box">
        <span>{{ $t('ORDER.REFUND.NOT_PREPAID') }}</span>
      </div>
    </woot-modal>
    <div
      v-if="!transformCreateBtn"
      style="gap: 0.8rem; margin-bottom: 2.4rem"
      class="flex-row flex-justify--between"
    >
      <span
        v-tooltip="ctaTooltipContent('create')"
        style="width: 100%"
        class="flex-row"
      >
        <woot-secondary-button
          size="medium"
          :name="
            productsInCart && productsInCart.length
              ? $t('ORDER.VIEW.TITLE')
              : $t('ORDER.CREATE.CREATE_NEW.TITLE')
          "
          back-icon="circleAdd"
          icon-color="stroke-green"
          icon-hover-color="semidarkgreen-stroke"
          custom-class="create-new-order-btn"
          :custom-style="shouldDisable"
          @click="onShowCreateOrder"
        />
      </span>
      <div
        style="cursor: pointer"
        class="create-new-order-btn--transformed"
        :title="$t('ORDER.SEARCH')"
        @click="transformCreateBtn = true"
      >
        <span>
          <icons
            name="search"
            color="green"
            size="medium"
            :custom-style="{ 'stroke-width': 2 }"
            :show-title="false"
          />
        </span>
      </div>
    </div>
    <div
      v-else
      style="gap: 0.8rem; margin-bottom: 2.4rem"
      class="flex-row flex-justify--between"
    >
      <span v-tooltip="ctaTooltipContent('create')" class="flex-row">
        <span
          style="cursor: pointer"
          :style="shouldDisable"
          class="create-new-order-btn--transformed"
          title="New Order"
          @click="onShowCreateOrder"
        >
          <icons
            name="circleAdd"
            color="stroke-green"
            size="medium"
            :custom-style="{ 'stroke-width': 2 }"
            :show-title="false"
          />
        </span>
      </span>
      <search-box
        :placeholder="$t('ORDER.SEARCH')"
        :value="search"
        :variant="true"
        :on-search="searchOrders"
        custom-class="search-box"
        @setSearch="setSearch"
      />
    </div>
    <div v-if="!uiFlags.fetchingList && orders.length === 0" class="no-order">
      <img
        :src="require('dashboard/assets/images/noOrder.svg')"
        alt="No Order"
      />
      <p class="no-items-error-message">
        {{ $t('ORDER.NO_ORDERS') }}
      </p>
    </div>
    <woot-loading-state
      v-if="uiFlags.fetchingList"
      :message="$t('ORDER.LOADING')"
    />
    <div v-if="!uiFlags.fetchingList && orders.length">
      <accordian-card
        v-for="(order, index) in orders"
        :key="order.id"
        :first-time-open="index === 0"
        :custom-desc="true"
      >
        <template v-slot:desc>
          <div class="order--top flex-row flex-justify--between">
            <span
              class="order--title title-h5"
              @click="e => openOrderHyperlink(e, order)"
            >
              {{ order.name }}
            </span>
            <span class="share-icon" @click="e => openSharePopup(e, order)">
              <icons name="share" color="green" size="semimedium" />
            </span>
          </div>
        </template>
        <template v-slot:close>
          <!-- Order Actions  -->
          <div
            v-if="order.shopify_order_status && order.shopify_financial_status"
            class="actions-list--container flex-row"
          >
            <span v-tooltip="ctaTooltipContent('edit', order)">
              <span
                class="title-h5_m action"
                :class="{
                  disabled: isEditActionDisable(order, 'edit'),
                }"
                @click="() => onOrderActionSelected('edit', order)"
              >
                Edit
              </span>
            </span>
            <span v-tooltip="ctaTooltipContent('refund', order)">
              <span
                class="title-h5_m action"
                :class="{
                  disabled: isRefundActionDisable(order, 'refund'),
                }"
                @click="() => onOrderActionSelected('refund', order)"
              >
                Refund
              </span>
            </span>
            <span v-tooltip="ctaTooltipContent('cancel', order)">
              <span
                class="title-h5_m action is-cancel"
                :class="{
                  disabled: isCancelActionDisable(order, 'cancel'),
                }"
                @click="() => onOrderActionSelected('cancel', order)"
              >
                Cancel
              </span>
            </span>
          </div>
          <!-- Order statuses -->
          <div class="flex-row flex-justify--start">
            <div
              v-if="
                order.shopify_order_status &&
                  order.shopify_order_status.status !== 'open'
              "
              class="close-button-container"
            >
              <span
                v-if="order.shopify_order_status"
                :style="
                  orderStatusColors(order.shopify_order_status.color_code)
                "
                class="body-b3"
              >
                {{ order.shopify_order_status.title }}
              </span>
            </div>
            <div
              v-if="order.shopify_financial_status"
              class="close-button-container"
            >
              <span
                v-if="order.shopify_financial_status"
                :style="
                  orderStatusColors(order.shopify_financial_status.color_code)
                "
                class="body-b3"
              >
                {{ order.shopify_financial_status.title }}
              </span>
            </div>
            <div
              v-if="order.shopify_fulfillment_status"
              class="close-button-container"
            >
              <span
                v-if="order.shopify_fulfillment_status"
                :style="
                  orderStatusColors(order.shopify_fulfillment_status.color_code)
                "
                class="body-b3"
              >
                {{ order.shopify_fulfillment_status.title }}
              </span>
            </div>
            <div v-else class="close-button-container">
              <span
                :style="{
                  background: '#F6BD16',
                  color: '#000',
                  border: 'none',
                }"
                class="body-b3"
              >
                {{ 'Unfulfilled' }}
              </span>
            </div>
          </div>
        </template>
        <table>
          <tbody>
            <tr
              v-if="order.last_name || order.first_name"
              class="flex flex-justify--between"
            >
              <td v-if="order.first_name || order.last_name">
                Invoice Name :
              </td>
              <td>
                {{ `${order.first_name || ''} ${order.last_name || ''}` }}
              </td>
            </tr>
            <tr v-if="order.purchase_datetime || true">
              <td>Placed On :</td>
              <td>{{ timeStamp(order.created_at) }}</td>
            </tr>
          </tbody>
        </table>
        <order-details
          v-if="order.cart"
          :orders="order.cart.line_items"
          :cart-data="order.cart"
          :order-data="order"
          read-only
        />
        <div
          v-if="
            order.shopify_fulfillment_status &&
              order.fulfillment.length &&
              order.fulfillment[0].tracking_url
          "
          class="tracking-container content-margin"
        >
          <span class="content-label body-b2 flex-row flex-justify--between">
            {{ $t('ORDER.TRACKING.TITLE') }}
            <span
              class="copy-icon"
              @click="onCopy('Tracking link should be copied')"
            >
              <icons name="copy" view="0 0 16 16" color="grey" size="normal" />
            </span>
          </span>
          <span class="content-body body-b2">
            <a
              :href="order.fulfillment[0].tracking_url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ order.fulfillment[0].tracking_url }}
            </a>
          </span>
        </div>
        <div
          v-if="order.shopify_shipping_address"
          class="tracking-container content-margin"
        >
          <span class="content-label body-b2 flex-row flex-justify--between">
            {{ $t('ORDER.SHIPPING.LABEL') }}
            <span
              class="copy-icon"
              @click="() => onCopy(getShippingAddress(order))"
            >
              <icons name="copy" view="0 0 16 16" color="grey" size="normal" />
            </span>
          </span>
          <span class="content-body text-dark body-b2">
            {{ getShippingAddress(order) }}
            <div>
              {{
                $t('ORDER.SHIPPING.PINCODE', {
                  pincode: order.shopify_shipping_address.zip,
                })
              }}
            </div>
          </span>
        </div>
        <div
          v-if="order.shopify_shipping_address"
          class="tracking-container content-margin"
        >
          <span class="content-label body-b2 flex-row flex-justify--between">
            {{ $t('ORDER.BILLING.LABEL') }}
          </span>
          <span class="content-body text-dark body-b2">
            {{ $t('ORDER.BILLING.SAME_AS_SHIPPING') }}
          </span>
        </div>
        <div v-if="order.order_notes" class="tracking-container content-margin">
          <span class="content-label body-b2 flex-row flex-justify--between">
            {{ $t('ORDER.NOTES.LABEL') }}
            <span class="copy-icon" @click="() => onCopy(order.order_notes)">
              <icons name="copy" view="0 0 16 16" color="grey" size="normal" />
            </span>
          </span>
          <span class="content-body text-dark body-b2">
            {{ order.order_notes }}
          </span>
        </div>
      </accordian-card>
    </div>
    <p
      v-if="!uiFlags.fetchingList && orders.length && hasNextPage"
      class="clear button load-more-conversations"
      @click="onPageChange"
    >
      {{ $t('ORDER.LOAD_MORE') }}
    </p>
    <p
      v-if="!uiFlags.fetchingList && orders.length && !hasNextPage"
      class="text-center text-muted end-of-list-text"
    >
      {{ $t('ORDER.LOADED') }}
    </p>

    <woot-delete-modal
      :show.sync="showShareModal"
      :on-close="closeSharePopup"
      :on-confirm="shareOrder"
      :title="$t('ORDER.SHARE.TITLE')"
      :message="shareMessage"
      :confirm-text="$t('ORDER.SHARE.CONFIRM')"
      :reject-text="$t('ORDER.SHARE.REJECT')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import copy from 'copy-text-to-clipboard';

import { getContrastingTextColor } from 'shared/helpers/ColorHelper';
import { frontendURL, conversationUrl } from 'dashboard/helper/URLHelper';

import AccordianCard from 'dashboard/components/AccordianCard';
import SearchBox from 'dashboard/routes/dashboard/settings/SearchBox';
import OrderDetails from '../shared/OrderDetails';
import * as types from 'shared/constants/googleEventType';
import alertMixin from 'shared/mixins/alertMixin';

import timeMixin from '../../../../../mixins/time';
import inboxMixin from 'shared/mixins/inboxMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import deepShopifyPermissionsMixin from 'shared/mixins/deepShopifyPermissionsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

export default {
  components: {
    AccordianCard,
    SearchBox,
    OrderDetails,
  },
  mixins: [
    alertMixin,
    messageFormatterMixin,
    googleAnalyticsMixin,
    inboxMixin,
    timeMixin,
    deepShopifyPermissionsMixin,
    mixPanelAnalyticsMixin,
  ],
  data() {
    return {
      search: '',
      page: 1,
      refundTransactionsLoading: false,
      showShareModal: false,
      showRefundModal: false,
      transformCreateBtn: false,
      showConfirmationModal: false,
      selectedOrder: {},
      refundAmt: 0,
      maximumRefundableAmt: 0,
      shouldRestockItem: false,
      shouldSendNotification: false,
      shouldRefundNow: true,
      refundTransactions: null,
      isEditedOrder: false,
      isRefunding: false,
      isCancelling: false,
      reasonsForCancellation: this.$t('ORDER.CANCELLATION.REASONS'),
      reasonForCancellation: this.$t('ORDER.CANCELLATION.REASONS')[0],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
      activeInbox: 'getSelectedInbox',
      meta: 'getShopifyOrdersMeta',
      uiFlags: 'getOrdersUIFlags',
      orders: 'getShopifyOrders',
      orderMode: 'getCurrentOrderMode',
      cartDetailsInCreateMode: 'getCartMeta',
      cartDetailsInEditMode: 'getCartMetaInEditMode',
      productsInCart: 'getProductsInCart',
      isDeepShopifyEnabled: 'accounts/getDeepShopifyEnabledFlag',
    }),
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    accountCurrency() {
      return this.$store.getters['accounts/getAccountCurrency'](this.accountId);
    },
    refundingActions() {
      return [
        { id: 'now', name: 'Refund Now', value: true },
        { id: 'later', name: 'Refund Later', value: false },
      ];
    },
    orderActionList() {
      return [
        { name: 'edit', isDisabled: false },
        { name: 'return', isDisabled: true },
        { name: 'refund', isDisabled: false },
        { name: 'cancel', isDisabled: false },
      ];
    },
    hasNextPage() {
      const isDisabled = this.page === Math.ceil(this.meta.count / 5);
      return !isDisabled;
    },
    cartDetails() {
      if (this.orderMode === 'edit') return this.cartDetailsInEditMode;
      return this.cartDetailsInCreateMode;
    },
    shareMessage() {
      return `Share Order "${this.selectedOrder.name}" with ${this.currentChat.meta?.sender?.name}`;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    shouldDisable() {
      return !this.permissions.canCreate
        ? { opacity: '60%', 'pointer-events': 'none' }
        : {};
    },
  },
  watch: {
    'currentChat.id': {
      immediate: true,
      handler(newChatId, oldChatId) {
        if (newChatId !== oldChatId) {
          this.resetOrderState();
        }
      },
    },
  },
  created() {
    this.$store.dispatch('setOrderMode', '');
    if (!this.contactId) {
      let unwatch = () => null;
      unwatch = this.$watch('contactId', () => this.fetchOrders());

      // clear watcher after sometime
      setTimeout(() => {
        unwatch();
      }, 60000);
    } else this.fetchOrders();
  },
  methods: {
    resetOrderState() {
      this.selectedOrder = {};
      this.showShareModal = false;
      this.showRefundModal = false;
      this.showConfirmationModal = false;
      this.$store.dispatch('emptyOrders');
    },
    orderEditFinancialStatus(order) {
      return (
        order.shopify_order_status.status !== 'open' ||
        order.shopify_financial_status.status === 'refunded' ||
        order.shopify_financial_status.status === 'voided' ||
        order.shopify_financial_status.status === 'partially_refunded'
      );
    },
    orderCancelFinancialStatus(order) {
      return (
        order.shopify_order_status.status !== 'open' ||
        order.shopify_financial_status.status === 'refunded' ||
        order.shopify_financial_status.status === 'voided' ||
        order.shopify_financial_status.status === 'partially_refunded'
      );
    },
    orderFulFillmentStatus(order) {
      return (
        order.shopify_fulfillment_status &&
        (order.shopify_fulfillment_status.status === 'fulfilled' ||
          order.shopify_fulfillment_status.status === 'partial')
      );
    },
    isEditActionDisable(order, action = null) {
      if (action && !this.checkIfPermission(action)) return true;

      return (
        this.orderEditFinancialStatus(order) ||
        this.orderFulFillmentStatus(order)
      );
    },
    isRefundActionDisable(order, action = null) {
      // check if permission is given to access refund flow
      if (action && !this.checkIfPermission(action)) return true;

      if (order.is_cod) return true;

      // TODO: brainstorm about this condition. Disabling this for now.
      // if (order.user_refund_amount === '0.00') return true;
      // if (order.user_refund_amount !== '0.00') return false;

      if (
        order.shopify_order_status.status === 'open' ||
        order.shopify_financial_status.status === 'voided' ||
        order.shopify_financial_status.status === 'refunded'
      )
        return true;

      // check if order status is cancelled
      if (
        order.shopify_order_status.status === 'cancelled' &&
        ['partially_refunded', 'paid', 'partially_paid'].includes(
          order.shopify_financial_status.status
        )
      )
        return false;

      return true;
    },
    isCancelActionDisable(order, action = null) {
      if (action && !this.checkIfPermission(action)) return true;

      return (
        this.orderCancelFinancialStatus(order) ||
        this.orderFulFillmentStatus(order)
      );
    },
    textColor(color) {
      return getContrastingTextColor(color);
    },
    orderStatusColors(color) {
      return {
        background: color || '#cdf0a2',
        border: 'none',
        color: color ? this.textColor(color) : '#000',
      };
    },
    timeStamp(d) {
      const date = new Date(d);

      const OPTIONS = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

      const formattedDate = new Intl.DateTimeFormat('en-US', OPTIONS).format(
        date
      );

      return `${formattedDate}`;
    },
    fetchOrders() {
      const { phone_number: phone, email, id: contactId } = this.contact;
      if (!phone && !email) {
        this.$store.dispatch('emptyOrders');
        return;
      }
      if (this.contact.id)
        this.$store.dispatch('fetchShopifyOrders', {
          page: 1,
          email,
          phone,
          contactId,
        });
    },
    onCopy(value) {
      copy(value);
      this.showAlert('Text Copied', 'info');
    },
    onCancelRefundModal() {
      this.showRefundModal = false;
      this.isEditedOrder = false;
      this.refundTransactions = null;
      this.selectedOrder = {};
    },
    onRefundOrder() {
      try {
        let assigneeId = null;
        try {
          assigneeId = this.currentChat?.meta?.assignee?.id;
          if (!assigneeId) {
            assigneeId = null;
          }
        } catch {
          assigneeId = null;
        }

        const data = {
          orderId: this.selectedOrder.id,
          note: 'Refunded by Agent',
          userRefundAmount: this.refundAmt,
          transactions: this.refundTransactions,
          isEditedOrder: this.selectedOrder.user_refund_amount !== '0.00',
          agentId: assigneeId,
          conversationId: this.currentChat?.id,
        };

        this.isRefunding = true;
        this.$store.dispatch('initiateOrderRefund', data).then(response => {
          this.isRefunding = false;
          this.shareRefundInformation(response.data);
          this.showAlert(this.$t('ORDER.REFUND.SUCCESS'), 'success');

          // Fetch all the orders
          this.$store.dispatch('fetchShopifyOrders', {
            page: 1,
            email: this.contact.email,
            phone: this.contact.phone_number,
            contactId: this.contact.id,
          });
          this.onCancelRefundModal();
        });
      } catch {
        this.isRefunding = false;
        this.showAlert(this.$t('ORDER.REFUND.ERROR'), 'error');
      }
    },
    setSearch(value) {
      const refetchAllproducts = !!this.search && value === '';
      if (refetchAllproducts) {
        this.$store.dispatch('fetchShopifyOrders', {
          page: 1,
          email: this.contact.email,
          phone: this.contact.phone_number,
          contactId: this.contact.id,
        });
      }
      this.search = value;
    },
    searchOrders() {
      if (this.search) {
        this.googleAnalyticsEvent(
          types.default.ORDERS_SEARCH,
          types.default.ORDERS_PANEL,
          this.search
        );
        this.$store.dispatch('fetchShopifyOrders', {
          query: this.search,
          contactId: this.contact.id,
          email: this.contact.email,
          phone: this.contact.phone_number,
          page: 1,
        });
      }
    },
    onPageChange() {
      this.page += 1;
      let payload = {
        contactId: this.contact.id,
        page: this.page,
        email: this.contact.email,
        phone: this.contact.phone_number,
        shouldLoadMore: true,
      };

      if (this.search)
        payload = {
          ...payload,
          query: this.search,
        };

      this.$store.dispatch('fetchShopifyOrders', { payload });
    },
    getShippingAddress(order) {
      if (order.shopify_shipping_address) {
        return `${order.shopify_shipping_address?.address_1} ${
          order.shopify_shipping_address.address_2
            ? order.shopify_shipping_address.address_2
            : ''
        }, ${order.shopify_shipping_address?.city}, ${
          order.shopify_shipping_address.province
            ? order.shopify_shipping_address.province
            : ''
        }`;
      }
      return '';
    },
    orderItemsMessagePayload(order) {
      let message = ``;
      order.cart.line_items.forEach((item, index) => {
        message +=
          item.fulfillable_quantity !== 0
            ? `\n${index + 1}. ${item.variant_item.product.name} - ${
                item.variant_item.title === 'Default Title'
                  ? ''
                  : item.variant_item.title
              } (Price: ${item.variant_item.price}, Quantity: ${item.quantity})`
            : '';
      });
      return message;
    },
    invoiceName(firstName, lastName) {
      return firstName || lastName
        ? `\n*Invoice Name:* ${firstName || ''} ${lastName || ''}`
        : '';
    },
    onSelectReason(reason) {
      this.reasonForCancellation = reason;
    },
    onOrderActionSelected(action, order) {
      switch (action) {
        case 'edit': {
          // perform any action/ run any function here
          this.selectedOrder = order;
          const { activeInbox } = this;

          const path = conversationUrl({
            accountId: this.currentChat.account_id,
            activeInbox,
            id: this.currentChat.id,
          });

          // set order mode to Edit
          this.$store.dispatch('setOrderMode', 'edit');

          // Setting Customer
          this.$store.dispatch('getCustomer', {
            customerId: this.cartDetailsInCreateMode.customer,
          });

          let activeOrder = {};
          // set active shopify order
          this.$store
            .dispatch('fetchDirectShopifyOrderDetail', {
              order_id: this.selectedOrder.id,
            })
            .then(response => {
              activeOrder = response.data;

              // set cart
              this.$store.dispatch('setCartInEditMode', {
                ...activeOrder.cart,
                orderId: activeOrder.id,
              });

              // set addresses
              if (activeOrder.shopify_shipping_address) {
                this.$store.dispatch('setSelectedAddress', {
                  addressType: 'shipping',
                  addressId: activeOrder.shopify_shipping_address.id,
                });
              }
              this.mix_panel_clicked_edit_order_successful();
              this.$router.push(frontendURL(path) + '/orders/cart');
            });

          break;
        }
        case 'return':
          break;
        case 'refund':
          this.showRefundModal = true;
          this.selectedOrder = order;
          this.refundTransactionsLoading = true;
          this.$store
            .dispatch('calculateOrderRefund', {
              orderId: this.selectedOrder.id,
            })
            .then(response => {
              this.refundTransactionsLoading = false;
              if (response.data.transactions.length > 0) {
                this.refundTransactions = response.data.transactions;
                this.refundTransactions[0].kind = 'refund';

                if (
                  this.selectedOrder.user_refund_amount !== '0.00' &&
                  this.selectedOrder.refunded_amount === '0.00'
                ) {
                  this.refundAmt = this.selectedOrder.user_refund_amount;
                  this.maximumRefundableAmt = this.selectedOrder.user_refund_amount;
                  this.isEditedOrder = true;
                } else {
                  this.refundAmt = this.refundTransactions[0].maximum_refundable;
                  this.maximumRefundableAmt = this.refundTransactions[0].maximum_refundable;
                }
              }
              this.mix_panel_clicked_refund_order_successful();
            })
            .catch(() => {
              this.refundTransactionsLoading = false;
            });
          break;
        case 'cancel': {
          this.showConfirmationModal = true;
          this.selectedOrder = order;

          if (!this.selectedOrder.is_cod)
            this.maximumRefundableAmt = this.selectedOrder.amount;
          this.refundAmt = this.selectedOrder.amount;

          if (this.selectedOrder.user_owe_amount !== '0.00') {
            this.refundAmt =
              this.selectedOrder.amount - this.selectedOrder.user_owe_amount;
          }
          break;
        }
        default:
          break;
      }
    },
    onCancelOrder() {
      let assigneeId = null;
      try {
        assigneeId = this.currentChat?.meta?.assignee?.id;
        if (!assigneeId) {
          assigneeId = null;
        }
      } catch {
        assigneeId = null;
      }

      let data = {
        order_id: this.selectedOrder.id,
        cancellation_reason: this.reasonForCancellation.value,
        agent_id: assigneeId,
        conversation_id: this.currentChat?.id,
      };

      const {
        permissions: { canRefund },
      } = this;

      if (this.shouldRefundNow && canRefund) {
        data = {
          order_id: this.selectedOrder.id,
          cancellation_reason: this.reasonForCancellation.value,
          refund: {
            note: 'Refunded just after cancelling order',
            shipping_full_refund: true,
            user_refund_amount: this.refundAmt,
          },
        };
      }
      this.isCancelling = true;
      // Cancel order
      this.$store
        .dispatch('cancelOrder', data)
        .then(() => {
          this.mixPanelOrderCancelled();
          this.isCancelling = false;

          if (this.selectedOrder.is_cod) this.shareCancelOrderCODMessage();
          else if (this.shouldRefundNow)
            this.shareCancelOrderWithRefundMessage(this.refundAmt);
          else this.shareCancelOrderWithoutRefundMessage();

          this.onCancel();
          // Fetch all the orders
          this.$store.dispatch('fetchShopifyOrders', {
            page: 1,
            email: this.contact.email,
            phone: this.contact.phone_number,
            contactId: this.contact.id,
          });
        })
        .catch(() => {
          this.isCancelling = false;
        });
    },
    shareCancelOrderWithoutRefundMessage() {
      try {
        const message = `*Your order has been cancelled.sss*\n*Order ID:* ${
          this.selectedOrder.name
        }\n${this.invoiceName(
          this.selectedOrder.shopify_customer.first_name,
          this.selectedOrder.shopify_customer.last_name
        )}\n*Placed on:* ${this.timeStamp(
          this.selectedOrder.created_at
        )}\n*Total Amount:* ${
          this.selectedOrder.amount
        }\n\n*Items:* ${this.orderItemsMessagePayload(this.selectedOrder)}`;

        if (this.isAnEmailChannel) {
          bus.$emit('openReplyBox', { message });
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          message,
        };

        this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
        this.paymentInformation = null;
        this.initialOrderData = null;
        this.showConfirmationModal = false;
      } catch (error) {
        // handle error
      }
    },
    shareCancelOrderWithRefundMessage(refundAmt) {
      try {
        const message = `*Your order has been cancelled and a refund has been initiated*\n*Order ID:* ${
          this.selectedOrder.name
        }\n${this.invoiceName(
          this.selectedOrder.shopify_customer.first_name,
          this.selectedOrder.shopify_customer.last_name
        )}\n*Placed on:* ${this.timeStamp(
          this.selectedOrder.created_at
        )}\n*Total Amount:* ${
          this.selectedOrder.amount
        }\n*Refund Initiated:* ${refundAmt}\n\n*Items:* ${this.orderItemsMessagePayload(
          this.selectedOrder
        )}`;

        if (this.isAnEmailChannel) {
          bus.$emit('openReplyBox', { message });
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          message,
        };

        this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
        this.paymentInformation = null;
        this.initialOrderData = null;
        this.showConfirmationModal = false;
      } catch (error) {
        // handle error
      }
    },
    shareCancelOrderCODMessage() {
      try {
        const message = `*Your order has been cancelled.*\n*Order ID:* ${
          this.selectedOrder.name
        }\n${this.invoiceName(
          this.selectedOrder.shopify_customer.first_name,
          this.selectedOrder.shopify_customer.last_name
        )}\n*Placed on:* ${this.timeStamp(
          this.selectedOrder.created_at
        )}\n*Total Amount:* ${
          this.selectedOrder.amount
        }\n*Payment Method:* COD\n\n*Items:* ${this.orderItemsMessagePayload(
          this.selectedOrder
        )}`;

        if (this.isAnEmailChannel) {
          bus.$emit('openReplyBox', { message });
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          message,
        };

        this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
        this.paymentInformation = null;
        this.initialOrderData = null;
        this.showConfirmationModal = false;
      } catch (error) {
        // handle error
      }
    },
    shareRefundInformation(refund) {
      try {
        const message = `Your refund of  *${this.accountCurrency} ${
          refund.user_refund_amount
        }* has been initiated.\n*Order ID:* ${
          refund.order.name
        }\n${this.invoiceName(
          refund.order.shopify_customer.first_name,
          refund.order.shopify_customer.last_name
        )}\n*Processed at:* ${refund.processed_at}\n*Refund Initiated:* ${
          refund.user_refund_amount
        }`;

        if (this.isAnEmailChannel) {
          bus.$emit('openReplyBox', { message });
          return;
        }

        const messagePayload = {
          conversationId: this.currentChat.id,
          message,
        };

        this.$store.dispatch('sendMessage', messagePayload);
        bus.$emit('scrollToMessage');
        this.paymentInformation = null;
        this.initialOrderData = null;
        this.showConfirmationModal = false;
      } catch (error) {
        // handle error
      }
    },
    async shareOrder() {
      try {
        if (!this.validateOrderOwnership()) {
          return;
        }

        const message = await this.constructOrderMessage();
        await this.sendOrderMessage(message);
      } catch (error) {
        this.showAlert('Error sharing order details', 'error');
      } finally {
        this.showShareModal = false;
      }
    },
    validateOrderOwnership() {
      // const { phone_number: contactPhone, email: contactEmail } = this.contact;
      const orderContact = this.selectedOrder.shopify_customer;

      if (!orderContact) {
        this.showAlert('Error: Unable to verify order ownership', 'error');
        return false;
      }

      // TODO: Uncomment this once we have validated that order contains the correct contact details
      // const normalizedContactPhone = !contactPhone.includes('+')
      //   ? `+${contactPhone}`
      //   : contactPhone;

      // const emailMatches = this.checkEmailMatch(
      //   contactEmail,
      //   orderContact.email
      // );
      // const phoneMatches = this.checkPhoneMatch(
      //   normalizedContactPhone,
      //   orderContact.phone_number
      // );

      // if (!emailMatches && !phoneMatches) {
      //   this.showAlert(
      //     'Error: Order does not belong to current customer',
      //     'error'
      //   );
      //   return false;
      // }

      return true;
    },
    checkEmailMatch(contactEmail, orderEmail) {
      return (
        contactEmail &&
        orderEmail &&
        contactEmail.toLowerCase() === orderEmail.toLowerCase()
      );
    },
    checkPhoneMatch(contactPhone, orderPhone) {
      return contactPhone && orderPhone && contactPhone === orderPhone;
    },
    getTrackingDescription() {
      if (
        this.selectedOrder.fulfillment.length &&
        this.selectedOrder.fulfillment[0].tracking_url
      ) {
        return `Tracking URL:\n${this.selectedOrder.fulfillment[0].tracking_url}`;
      }
      return '';
    },
    constructOrderMessage() {
      const { selectedOrder } = this;
      const desc = this.getTrackingDescription();

      return `*Order Details* - *${selectedOrder.name}*${this.invoiceName(
        selectedOrder.first_name,
        selectedOrder.first_name
      )}\n\n*Placed On:* ${this.timeStamp(
        selectedOrder.created_at
      )}\n*Delivery Address:* ${this.getShippingAddress(
        selectedOrder
      )}\n*Pin Code:* ${
        selectedOrder.shopify_shipping_address.zip
      }\n\n*Items:* ${this.orderItemsMessagePayload(
        selectedOrder
      )}\n\n*Order summary*:\n*Subtotal*: ${selectedOrder.current_subtotal_price ||
        0.0}\n*Total Tax*: ${selectedOrder.current_total_tax ||
        selectedOrder.total_tax}\n*Discount*: ${
        selectedOrder.applied_discount
      }\n*Total Amount*: ${selectedOrder.amount ||
        selectedOrder.amount}\n\n${desc}`;
    },
    async sendOrderMessage(message) {
      if (this.isAnEmailChannel) {
        bus.$emit('openReplyBox', { message });
        return;
      }

      const messagePayload = {
        conversationId: this.currentChat.id,
        message,
      };

      await this.$store.dispatch('sendMessage', messagePayload);
      bus.$emit('scrollToMessage');
    },
    closeSharePopup() {
      this.showShareModal = false;
    },
    openSharePopup(e, order) {
      e.stopPropagation();
      this.selectedOrder = order;
      this.showShareModal = true;
    },
    openOrderHyperlink(e, order) {
      if (order.shopify_order_url) window.open(order.shopify_order_url);
    },
    onShowCreateOrder() {
      const { activeInbox } = this;

      const path = conversationUrl({
        accountId: this.currentChat.account_id,
        activeInbox,
        id: this.currentChat.id,
      });

      if (this.orderMode === 'edit')
        this.$store.dispatch('setOrderMode', 'create');

      // Fetch the latest cart
      this.getCart();
      this.$router.push(frontendURL(path) + '/orders/cart');
    },
    getCart() {
      if (!this.isDeepShopifyEnabled) return;

      const { phone_number: phoneNumber, email, name } = this.contact;
      if (!phoneNumber && !email) {
        this.$store.dispatch('emptyCustomerCart');
        this.$store.dispatch('resetDraftOrder');
        return;
      }
      const payload = {
        phone_number: phoneNumber || '',
        email: email || '',
        firstName: name || '',
      };
      this.$store.dispatch('getCustomerCart', payload);
    },
    onCancel() {
      this.showConfirmationModal = false;
      this.selectedOrder = {};
    },
    checkIfPermission(action) {
      const { permissions } = this;

      switch (action) {
        case 'create':
          return permissions.canCreate;
        case 'edit':
          return permissions.canEdit;
        case 'refund':
          return permissions.canRefund;
        case 'cancel':
          return permissions.canCancel;
        default:
          return false;
      }
    },
    ctaTooltipContent(action, order) {
      const message = {
        NO_PERMISSION: this.$t('ORDER.CTA_PERMISSION_MESSAGES.NO_PERMISSION'),
        NOT_ALLOWED: this.$t('ORDER.CTA_PERMISSION_MESSAGES.NOT_ALLOWED'),
      };

      if (!this.checkIfPermission(action)) return message.NO_PERMISSION;

      switch (action) {
        case 'create': {
          return null;
        }
        case 'edit': {
          return this.isEditActionDisable(order) && message.NOT_ALLOWED;
        }
        case 'refund': {
          return this.isRefundActionDisable(order) && message.NOT_ALLOWED;
        }
        case 'cancel': {
          return this.isCancelActionDisable(order) && message.NOT_ALLOWED;
        }
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.search-box {
  margin-bottom: 0;
  width: 84%;
}

.content-box {
  padding: $space-slab $space-medium !important;

  .refund-amt-input {
    width: $space-three * 100;
  }
}

.agent-dropdown-button {
  border: 1px solid $neutral-grey-600;
  border-radius: $border-radius-smaller;
  padding: $space-small;
  width: 100%;

  .dropdown-option {
    color: $text-dark;
    font-size: $font-size-small;
    font-weight: $font-weight-black;
    line-height: $height-h5;
    margin-left: $space-small;
    text-transform: capitalize;
    width: 100%;
  }
}

.create-new-order-btn {
  border: 1px solid $neutral-grey-400;
  color: $text-dark;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  line-height: $height-h5;
  padding: $space-small $space-slab $space-small $space-normal;
  text-transform: none;
  width: 100%;

  &--transformed {
    border: 1px solid $neutral-grey-400;
    border-radius: $border-radius-smaller;
    height: 4.4rem;
    padding: $space-small;

    &:hover {
      border: 1px solid $pg-1-600;
    }
  }

  ::v-deep .icon {
    stroke-width: 2;
  }
}

.order--top {
  width: 100%;

  .order--title {
    color: $secondary-blue;
    cursor: pointer;
  }

  .share-icon {
    cursor: pointer;

    &:hover {
      ::v-deep .icon {
        fill: $pg-1-600;
      }
    }
  }
}

.order-date {
  color: $neutral-grey-800;
  font-size: $font-size-mini;
  line-height: 2;

  .order-date-text {
    padding-left: $space-smaller;
    color: $neutral-grey-600;
  }
}

.actions-list {
  &--container {
    gap: $space-normal;
    margin: $space-slab 0;
    text-transform: capitalize;

    .action {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .is-cancel {
      color: $warn-red-400;
    }

    .disabled {
      color: $neutral-grey-500;
      pointer-events: none;
    }
  }
}

.close-button-container {
  span {
    background: $pg-1-100;
    border: 1px solid $pg-1-200;
    border-radius: 2rem;
    color: $neutral-grey-800;
    padding: $space-smaller $space-small;

    &:first-child {
      margin-right: $space-small;
    }
  }
}
table {
  border-collapse: inherit;
  border-top: 1px dashed $neutral-grey-400;
  margin: $space-slab 0 0;
  padding: $space-slab 0;
  table-layout: auto;
  width: 100%;
}
table tr {
  border-bottom: none;
}
table td {
  padding: $zero;
  padding-bottom: $space-smaller;
  font-size: $font-size-small;
  line-height: 2.2rem;
  color: $neutral-grey-800;
  vertical-align: baseline;

  &:first-child {
    color: $neutral-grey-600;
    white-space: nowrap;
    padding-right: $space-smaller;
  }
}
.content-margin {
  color: $neutral-grey-600;
  margin-bottom: $space-slab;

  .copy-icon {
    cursor: pointer;
    padding: $space-six $zero $space-smaller $zero;

    &:hover {
      ::v-deep .icon {
        fill: $neutral-grey-800;
      }
    }
  }
}
.tracking-container {
  ::v-deep a {
    word-break: break-all;
    color: $secondary-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}
.form-button {
  margin-top: $space-medium;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-order {
  margin-top: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 12rem;
    margin-bottom: $space-large;
  }

  p {
    color: $secondary-blue;
    font-size: $font-size-default;
    line-height: $space-medium;
  }
}
.load-more-conversations {
  border: 0;
  color: $pg-1-500;
  font-size: $font-size-small;
  margin: 0;
  padding: $space-normal;
  width: 100%;
}
.end-of-list-text {
  padding: $space-normal;
}
.loading-state {
  padding: $zero;
}
</style>
