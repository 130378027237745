<template>
  <inbox-filter
    v-if="isCallFeatureAvailable"
    v-tooltip.right="tooltipMsg"
    custom-button
    :option-list="getAvailableVoiceInboxes"
    @click="openVoiceCallModal"
  >
    <span
      :class="{
        'phone-icon': isCallFeatureAvailable,
        'phone-icon--disabled': !isCallFeatureAvailable,
        jiggle: callStatesFlags.showCallPopup,
        ...spanClass,
      }"
    >
      <icons
        :name="
          isCallInProgress || callStatesFlags.showCallNotification
            ? 'phone'
            : 'call'
        "
        :title="isCallInProgress ? 'call in progress' : 'call'"
        :color="callIconColor"
        :size="iconSize"
      />
    </span>
  </inbox-filter>
</template>

<script>
import { mapGetters } from 'vuex';
import InboxFilter from 'dashboard/components/widgets/conversation/filter/InboxFilter';
import callMixin from 'shared/mixins/callMixin';

export default {
  components: { InboxFilter },
  mixins: [callMixin],
  props: {
    iconSize: {
      type: String,
      default: 'medium',
    },
    spanClass: {
      type: Object,
      default: () => ({}),
    },
    tooltipMsg: {
      type: String,
      default: '',
    },
    callIconColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    ...mapGetters({
      callStatesFlags: 'getCallStatesUiFlags',
      currentCallState: 'getCurrentCallState',
    }),
    iconName() {
      return this.isCallInProgress || this.callStatesFlags.showCallNotification
        ? 'phone'
        : 'call';
    },
    iconTitle() {
      return this.isCallInProgress ? 'call in progress' : 'call';
    },
    spanClasses() {
      return {
        'phone-icon': this.isCallFeatureAvailable,
        'phone-icon--disabled': !this.isCallFeatureAvailable,
        jiggle: this.callStatesFlags.showCallPopup,
        [this.spanClass]: true,
      };
    },
    eventName() {
      return this.currentCallState.eventName;
    },
    isCallInProgress() {
      return this.eventName !== 'HANGUP' && this.eventName !== '';
    },
  },
  methods: {
    async openVoiceCallModal(selectedInbox) {
      const { is_web_rtc: isWebRTC } = selectedInbox;
      if (isWebRTC) {
        await this.$knowlarity.ensureInitialized();
        this.$knowlarity.toggleWidget();
      } else {
        this.$emit('showCallPopup');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
.phone-icon {
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  ::v-deep .icon {
    transition: fill 0.2s ease-out;

    &:hover {
      fill: $pg-1;
    }
  }
}
</style>
