export const TWITTER_USERNAME_REGEX = /(^|[^@\w])@(\w{1,15})\b/g;
export const TWITTER_USERNAME_REPLACEMENT =
  '$1<a href="http://twitter.com/$2" target="_blank" rel="noreferrer nofollow noopener">@$2</a>';

export const TWITTER_HASH_REGEX = /(^|\s)#(\w+)/g;
export const TWITTER_HASH_REPLACEMENT =
  '$1<a href="https://twitter.com/hashtag/$2" target="_blank" rel="noreferrer nofollow noopener">#$2</a>';

export const STRONG_REGEX = /^(?:(\*))([^*]*)(?:\*)/g;

// Define downloadable content patterns
export const DOWNLOAD_EXTENSIONS = [
  '.mp3',
  '.wav',
  '.ogg', // Audio
  '.pdf',
  '.doc',
  '.docx', // Documents
  '.zip',
  '.rar', // Archives
  '.jpg',
  '.png',
  '.gif', // Images
  '.mp4',
  '.avi',
  '.mov', // Video
];

export const CUSTOM_DOWNLOAD_PATTERNS = [
  'konnect.knowlarity.com/konnect/api/v1',
];

export const DOWNLOAD_PATTERNS = [...CUSTOM_DOWNLOAD_PATTERNS];
