<template>
  <div>
    <woot-modal-new
      :show="true"
      :disable-btn="isFormNotValid"
      :navigate-to="agentData.navigateTo"
      :on-close="() => $emit('onClose')"
      :loading-btn="loading"
      @submit="onSubmission"
    >
      <woot-modal-header-new :header-content="$t('AGENT_MGMT.ADD.DESC')">
        <span class="title-h5 text-blue" v-text="modalHeader" />
      </woot-modal-header-new>
      <template v-slot:paginated-components>
        <personal-details
          key="personal-detail"
          :agent-id="agentId"
          :agent-name.sync="agentName"
          :phone-number.sync="phoneNumber"
          :agent-email.sync="agentEmail"
          :agent-type.sync="agentType"
          :inbox-ids.sync="inboxIds"
          :web-rtc-id.sync="webRTCId"
          @formValidity="
            $event => (isFormNotValid = { ...isFormNotValid, 0: $event })
          "
        />
        <employee-details
          key="employee-detail"
          :email-sign-html.sync="emailSignHtml"
          :email-sign-md.sync="emailSignMd"
          :markdown-mode.sync="markdownMode"
          :concurrency-enabled.sync="concurrencyEnabled"
          :concurrency-limit.sync="concurrencyLimit"
          :agent-id="agentId"
          @formValidity="
            $event => (isFormNotValid = { ...isFormNotValid, 1: $event })
          "
        />
      </template>
    </woot-modal-new>
  </div>
</template>

<script>
import PersonalDetails from 'dashboard/routes/dashboard/settings/agents/add/PersonalDetails';
import EmployeeDetails from 'dashboard/routes/dashboard/settings/agents/add/EmployeeDetails';

import alertMixin from 'shared/mixins/alertMixin';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';

import * as types from 'shared/constants/googleEventType';

export default {
  components: { PersonalDetails, EmployeeDetails },
  mixins: [googleAnalyticsMixin, alertMixin, mixPanelAnalyticsMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    agentData: {
      type: Object,
      default: () => {},
    },
    inboxList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      agentId: '',
      agentName: '',
      phoneNumber: '',
      agentEmail: '',
      agentType: 'agent',
      emailSignHtml: '',
      emailSignMd: '',
      webRTCId: '',
      markdownMode: true,
      concurrencyEnabled: false,
      concurrencyLimit: 5,
      currentStep: 0,
      inboxIds: [],
      // indexing is done to disable button for a specific page in modal. Number of indexes = number of components in the modal
      isFormNotValid: {
        0: true,
        1: this.concurrencyEnabled && this.concurrencyLimit,
      },
    };
  },
  computed: {
    modalHeader() {
      if (!this.agentData.id) return this.$t('AGENT_MGMT.ADD.TITLE');
      return this.$t('AGENT_MGMT.EDIT.TITLE');
    },
  },
  created() {
    this.inboxIds = this.inboxList;

    const { id: agentId } = this.agentData;

    if (agentId) {
      this.$store.dispatch('agents/get').then(data => {
        this.inboxIds = data.filter(agent => agent.id === agentId)[0].inboxes;
      });
      this.hydrateFields();
    }
  },
  methods: {
    hydrateFields() {
      const {
        id,
        name,
        phone_number: phoneNumber,
        email,
        role,
        concurrency_enabled: concurrencyEnabled,
        concurrency_limit: concurrencyLimit,
        inboxes,
        signature,
        signature_type: signatureType,
        web_rtc_id: webRTCId,
      } = this.agentData;

      this.agentId = id;
      this.agentName = name;
      this.phoneNumber = phoneNumber;
      this.agentEmail = email;
      this.agentType = role;
      this.concurrencyEnabled = concurrencyEnabled;
      this.concurrencyLimit = concurrencyLimit;
      this.inboxIds = inboxes;
      this.webRTCId = webRTCId;

      if (signatureType === 'markdown') {
        this.emailSignMd = signature;
        this.markdownMode = true;
      } else {
        this.emailSignHtml = signature;
        this.markdownMode = false;
      }
    },
    onSubmission() {
      if (this.agentId) {
        this.editAgent();
        this.mix_panel_clicked_settings_agent_submit('Agent Edit');
      } else {
        this.addAgent();
        this.mix_panel_clicked_settings_agent_submit('Agent Add');
      }
    },
    preparePayload(agentId) {
      let payload = {
        name: this.agentName,
        email: this.agentEmail,
        role: this.agentType,
        phone_number: this.phoneNumber,
        concurrency_enabled: this.concurrencyEnabled,
        inbox_ids: this.inboxIds.map(ib => ib.id),
        web_rtc_id: this.webRTCId,
      };

      if (agentId) payload.id = agentId;

      if (this.emailSignHtml)
        payload = {
          ...payload,
          signature: this.emailSignHtml,
          signature_type: !this.markdownMode ? 'html' : 'markdown',
        };
      else if (this.emailSignMd)
        payload = {
          ...payload,
          signature: this.emailSignMd,
          signature_type: !this.markdownMode ? 'html' : 'markdown',
        };
      else
        payload = {
          ...payload,
          signature: '',
          signature_type: 'markdown',
        };
      if (this.concurrencyEnabled)
        payload = { ...payload, concurrency_limit: +this.concurrencyLimit };

      return payload;
    },
    async addAgent() {
      this.loading = true;

      this.mixPanelAddCollaboratorFinished();

      this.googleAnalyticsEvent(
        types.default.ADD_AGENT,
        types.default.AGENT_SETTINGS,
        types.default.AGENT_SETTINGS
      );

      const payload = this.preparePayload();

      await this.$store
        .dispatch('agents/create', payload)
        .then(() => {
          this.showAlert(
            this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'),
            'success'
          );

          this.$emit('onClose');
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.showAlert(
              this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE'),
              'warning'
            );
            return;
          }
          this.showAlert(this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE'), 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async editAgent() {
      this.loading = true;

      this.googleAnalyticsEvent(
        types.default.EDIT_AGENT,
        types.default.AGENT_SETTINGS,
        types.default.AGENT_SETTINGS
      );

      const payload = this.preparePayload(this.agentId);

      await this.$store
        .dispatch('agents/update', payload)
        .then(() => {
          this.$emit('updateCurrAgent', {
            ...this.agentData,
            ...payload,
            inboxes: this.inboxIds,
          });
          this.showAlert(
            this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.$emit('onClose');
        })
        .catch(() => {
          this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'), 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
