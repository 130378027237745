<template>
  <div class="container--heading">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.VOICE_CHANNEL.KNOWLARITY_AGENTS.TITLE')"
      :header-content="
        $t('INBOX_MGMT.ADD.VOICE_CHANNEL.KNOWLARITY_AGENTS.DESC')
      "
    />
    <br />
    <div
      v-if="knowlarityAgents.length"
      class="agents-heading mb-2 title-h4 text-dark"
    >
      {{ $t('INBOX_MGMT.ADD.VOICE_CHANNEL.KNOWLARITY_AGENTS.SUB_TITLE') }}
    </div>

    <div v-if="!loading">
      <div v-if="knowlarityAgents.length" class="container--agents">
        <div
          v-for="agent in knowlarityAgents"
          :key="agent.id"
          class="flex-row agent-info"
        >
          <avatar
            :username="`${agent.first_name} ${agent.last_name}`"
            rounded
            :size="24"
            :colored="true"
            :custom-style="{
              'box-shadow': 'none',
              border: 'none',
            }"
          />
          <span v-tooltip.right="agent.phone" class="title-h5 text-dark">
            {{ `${agent.first_name} ${agent.last_name}` }}
          </span>
        </div>
      </div>
      <div
        v-else
        class="body-b2 text-light text-align"
        v-text="'NO AGENTS FOUND'"
      />
    </div>
    <woot-spinner v-else class="loading-spinner" color="lime-green" />
  </div>
</template>
<script>
import Avatar from 'dashboard/components/widgets/Avatar';
import PageHeader from 'dashboard/routes/dashboard/settings/SettingsSubPageHeader';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Avatar,
    PageHeader,
  },
  mixins: [alertMixin],
  props: {
    channelId: {
      type: [String, Number],
      default: () => null,
    },
  },
  data() {
    return {
      knowlarityAgents: [],
      loading: false,
    };
  },
  async created() {
    const { channelId } = this;

    this.loading = true;
    this.knowlarityAgents = await this.$store
      .dispatch('getKnowlarityAgents', {
        channelId,
      })
      .catch(() => {
        this.knowlarityAgents = [];
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.VOICE_CHANNEL.ERROR.FETCH_KNOWLARITY'),
          'error',
          5000
        );
      });

    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.info-card-header {
  color: $secondary-blue;
}

.agents-heading {
  background: $neutral-grey-300;
  border-radius: $border-radius-smaller;
  padding: $space-smaller $space-one $space-smaller $space-one;
}

.mb-2 {
  margin-bottom: $space-two;
}

.container {
  &--agents {
    padding: 0 $space-small;

    .agent-info {
      gap: $space-small;
      margin-bottom: $space-normal;
    }
  }
}
</style>
